import { FilterType } from 'bindings/filters/FilterType';
import { ValueKey } from 'bindings/reports/generic/ValueKey';
import { Granularity } from 'bindings/time/Granularity';
import equal from 'deep-equal';
import optionGrey from 'images/icon/options_grey.png';
import CompanyBlackImage from 'images/menu_icon/company_black.svg';
import CompanyMapBlackImage from 'images/menu_icon/company_map_black.svg';
import { ITag } from 'proto/protobufs';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AAdditionalColumns, additionalColumnTypeToFilterTypeAndPath, getFirstValueNotPieChart } from '../../atoms/additionalColumns';
import { AAdditionalFieldColumns, fieldTypeToFilterType } from '../../atoms/additionalFieldColumns';
import { ShelfAuditTemplate, getShelfAuditTemplates } from '../../atoms/filter/shelfAuditFilterAtom';
import { AFrequencyEventType } from '../../atoms/global/frequency';
import { AGranularity } from '../../atoms/global/granularity';
import { ASelectedExternalMappingId, AtomSelectedExternalMappingSelector } from '../../atoms/global/mappings';
import { ASelectedFormId } from '../../atoms/global/selectedForm';
import { AViews, CompanyView, DbView, NewView, ViewCompanyColumns, deleteView, getViews, postView, putView } from '../../atoms/global/views';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import AdvancedFilters from '../../components_v2/filter/AdvancedFilters';
import { FilterId, FilterResult, FilterTree, filterTreeLength, isLastFormDateFilter } from '../../components_v2/filter/model/Model';
import { FilterParameter, convertFilters } from '../../components_v2/filter/pages/FilterList';
import InputSearch from '../../components_v2/input/InputSearch';
import { ButtonStyle } from '../../components_v2/popup/PopupCreation';
import PopupDeletion from '../../components_v2/popup/PopupDeletion';
import ToolbarFilterButton from '../../components_v2/toolbarFilter/ToolbarButton';
import { TagType } from '../../components_v2/toolbarFilter/tags/Model';
import { getTags } from '../../components_v2/toolbarFilter/tags/action';
import PopupView, { ViewCreation } from '../../components_v2/view/PopupView';
import ViewPicker from '../../components_v2/view/ViewPicker';
import { Translate, translateToString } from '../../styles/global/translate';
import { useFunctionState } from '../../utils/customHooks';
import { AlertRes } from '../alert/AlertProvider';
import useAlert from '../alert/UseAlert';
import { ToolbarBox, ToolbarImage } from '../globals/defaultToolbar/style/Style';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { getClientStatuses } from '../import/actions';
import MapView, { FetchKey } from '../map/MapView';
import PageSwitch from '../map/PageSwitch';
import { TypeFile } from '../orders/model/Model';
import PermissionContext from '../permissions/PermissionContext';
import Restricted from '../permissions/Restricted';
import { ModalState } from '../products/model';
import Companies, { COMPANY_COLUMN_CATEGORIES_MAP, ColumnResume, additionalColumnIdFormatter, additionalFieldColumnIdFormatter, allCompaniesAndAdditionalColumns, calculatedFieldIdFormatter } from './Companies';
import { filterIdToName } from './data/CompanyColumns';
import { exportCompanies, exportData, getCompanyFilters, getEventTypes } from './data/action';
import { CompanyStatus, EventType } from './model/Model';
import { SidebarButton } from './style/Style';
import { ACalculatedFields } from '../../atoms/calculatedFields';
import { Column, ColumnOrganizer } from './popup/ColumnOrganizer';

export const DEFAULT_FILTER_RESULT: FilterResult = {
	values: {
		array: [],
		combinator: 'and'
	},
	formatted: undefined
};

type Pathname = '/companies' | '/map'

export default function CompaniesAndMapView(props: { setToolBarState: (value: ToolbarState) => void }) {
	const history = useHistory();
	const [selectedPath, setSelectedPath] = useFunctionState<Pathname>(history.location.pathname, ({ newValue }) => {
		window.history.replaceState(null, '', newValue);
		return newValue;
	});

	const ref = React.useRef<{ hasFiltersOn?:() => boolean }>();
	const [childtoolBarState, setChildToolBarState] = React.useState<ToolbarState>();

	// Views
	const [views, setViews] = useRecoilState(AViews);
	const [isViewOpen, setIsViewOpen] = React.useState<boolean>(false);
	const [deletionModalState, setDeletionModalState] = React.useState<ModalState<DbView<CompanyView>>>({ isOpen: false });
	const [selectedView, setSelectedView] = useFunctionState<DbView<CompanyView> | undefined>(views.companies?.find(v => v.id.toString() === localStorage.getItem('company_view_id')),
		({ newValue }) => {
			if (newValue) {
				localStorage.setItem('company_view_id', newValue.id.toString());
			} else {
				localStorage.removeItem('company_view_id');
			}
			return newValue;
		}
	);
	const [editedView, setEditedView] = React.useState<DbView<CompanyView> | undefined>();

	// Filters
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const [filterResult, setFilterResult] = React.useState<FilterResult | undefined>(selectedView?.data.filters);
	const [isOpenSummary, setOpenSummary] = React.useState<boolean>(false);
	const [isFilterOpen, setFilterOpen] = React.useState<boolean>(false);
	const [selectedFilter, setSelectedFilter] = React.useState<FilterId>();
	const [allColumns, setAllColumns] = React.useState<{ id: FilterId, name: string }[]>([]);

	// Columns
	const calculatedFields = useRecoilValue(ACalculatedFields);
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const allCompaniesColumnsAndAdditionalColumns: ColumnResume[] = React.useMemo(() => allCompaniesAndAdditionalColumns(additionalColumns, additionalFieldColumns, calculatedFields), [additionalColumns, additionalFieldColumns, calculatedFields]);
	const localColumns: ViewCompanyColumns[] = JSON.parse(localStorage.getItem('company_hidden_columns') || '[]');
	const baseHiddenColumns = Array.isArray(localColumns) && localColumns.length > 0 ? localColumns.filter(l => l.hidden).map(l => l.column) : allCompaniesColumnsAndAdditionalColumns.filter(c => c.hidded).map(c => c.name);
	const baseSortColumns = Array.isArray(localColumns) && localColumns.length > 0 ? localColumns.map(l => l.column) : allCompaniesColumnsAndAdditionalColumns.map(c => c.name);
	const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(baseHiddenColumns);
	const [sortColumns, setSortColumns] = React.useState<string[]>(baseSortColumns);
	const [hidableColumns, setHidableColumns] = React.useState<Column[]>();

	// Other data
	const [statuses, setStatuses] = React.useState<CompanyStatus[]>([]);
	const [tags, setTags] = React.useState<ITag[]>([]);
	const [eventTypes, setEventTypes] = React.useState<EventType[]>([]);
	const [searchName, setSearchName] = React.useState<string>();
	const [tabType, setTabType] = React.useState<FetchKey>(selectedView?.data.mapTabType ?? 'status');
	const alert = useAlert();
	const { isAllowedTo } = React.useContext(PermissionContext);
	const dispatch = useDispatch();
	const selectedColumnEventType = useRecoilValue(AFrequencyEventType);
	const selectedExternalMappingId = useRecoilValue(ASelectedExternalMappingId);
	const selectedColumnFormId = useRecoilValue(ASelectedFormId);
	const [shelfAuditTemplates, setShelfAuditTemplates] = React.useState<ShelfAuditTemplate[]>([]);
	const granularity = useRecoilValue(AGranularity);


	React.useEffect(() => {
		const view = views.companies?.find(v => v.id.toString() === localStorage.getItem('company_view_id'));
		setSelectedView(view);
		if (view) {
			setFilterResult(view.data.filters);
			if (view.data.mapTabType) {
				setTabType(view.data.mapTabType);
			}
			const columns = view?.data.columns ?? JSON.parse(localStorage.getItem('company_hidden_columns') || '[]');
			if (columns) {
				setHiddenColumns(columns.filter(l => l.hidden).map(l => l.column));
				setSortColumns(columns.map(l => l.column));
			}
		}
	}, [views]);

	const deleteLocalView = (view: DbView<CompanyView>) => {
		if (view) {
			setDeletionModalState({ isOpen: true, data: view });
		}
	};

	React.useEffect(() => {
		getCompanyFilters().then(async response => {
			const allFirstValues = await getFirstValueNotPieChart();
			const additionalFirstValues = allFirstValues.filter(fv => fv.column_type === 'additional_column');
			const calculatedFirstValues = allFirstValues.filter(fv => fv.column_type === 'calculated_field');
			const newFilterParams = [...response.filters];
			let newAllColumns = [...response.columns.map(id => ({ id, name: filterIdToName(id) }))];
			if (isAllowedTo({ objectAction: 'ReadForm' })) {
				newFilterParams.push(
					additionalColumns.reduce((acc: FilterParameter, x) => {
						if (x.type === 'ReportColumn') return acc;
						const { type, path } = additionalColumnTypeToFilterTypeAndPath(x.type);
						acc.filters.push({
							id: { additional_columns: [{ id: x.id, path: path ?? null, name: false }] },
							name: x.name,
							type
						});
						return acc;
					}, { category: translateToString('additional_columns'), filters: [] })
				);
				newFilterParams.push(
					additionalFieldColumns.reduce((acc: FilterParameter, x) => {
						acc.filters.push({
							id: { additional_field_columns: [x.field_id] },
							name: x.field_name,
							type:  fieldTypeToFilterType(x.field_type)
						});
						return acc;
					}, { category: translateToString('additional_field_columns'), filters: [] }));

				newFilterParams.push(calculatedFields.reduce((acc: FilterParameter, x) => {
					let type: FilterType;
					let path: ValueKey | undefined = undefined;
					const firstValue = calculatedFirstValues.find(fv => fv.id === x.id);
					if (!firstValue || !firstValue.value) {
						type = 'nullable';
					} else if ('int' in firstValue.value || 'float' in firstValue.value) {
						type = 'numeric';
					} else if ('percentage' in firstValue.value || 'evolution' in firstValue.value) {
						type = 'percentage';
					} else if ('key' in firstValue.value || 'text' in firstValue.value) {
						type = 'string';
					} else {
						type = 'nullable';
					}
					if (firstValue && firstValue.value) {
						path = Object.keys(firstValue.value)[0] as ValueKey;
					}
					acc.filters.push({
						id: { calculated_field_columns: [{ id: x.id, path: path ?? null, name: false }] },
						name: x.field_name,
						type
					});
					return acc;
				}, { category: translateToString('calculated_fields'), filters: [] }));
				
				newAllColumns = [
					...newAllColumns,
					...additionalColumns.map(ac => ({ id: { additional_columns: [{ id: ac.id, name: false, path: null }] }, name: additionalColumnIdFormatter(ac.id) })),
					...additionalFieldColumns.map(ac => ({ id: { additional_field_columns: [ac.field_id] }, name: additionalFieldColumnIdFormatter(ac.field_id) })),
					...calculatedFields.map<{id: FilterId, name: string }>(cf => ({ id: { calculated_field_columns: [{ id: cf.id, name: false, path: null }] }, name: calculatedFieldIdFormatter(cf.id) }))
				];
			}
			setFilterParams(newFilterParams);
			setAllColumns(newAllColumns);
		});
		getClientStatuses().then(response => {
			setStatuses(response.data);
		});

		getTags(TagType.COMPANY).then(setTags);
		getEventTypes().then(setEventTypes);
		getShelfAuditTemplates().then(setShelfAuditTemplates);
	}, []);

	const columnsToView = (): ViewCompanyColumns[] => sortColumns.map(c => ({
		column: c,
		hidden: hiddenColumns.includes(c)
	}));

	const saveView = React.useCallback(async(v: ViewCreation) => {
		const data: NewView<'companies'> = {
			data: {
				filters: filterResult ?? DEFAULT_FILTER_RESULT,
				columns: columnsToView(),
				name: v.name,
				mapTabType: tabType,
				type: 'companies'
			},
			public: v.public
		};
		await (v.id ? putView(v.id, data) : postView(data));
		getViews().then(newViews => {
			setSelectedView(newViews.companies.find(nv => v.id === nv.id || !views.companies.find(v => v.id == nv.id)));
			setViews(newViews);
		});
	}, [views, filterResult, hiddenColumns, sortColumns, tabType]);

	const LocalExportCompanies = () => {
		dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
		let new_filters: FilterTree | undefined;
		if (selectedPath === '/map') {
			new_filters = {
				and: [{
					val: {
						column: 'latitude',
						operator: 'not_empty',
					}
				},
				{
					val: {
						column: 'longitude',
						operator: 'not_empty',
					}
				}],
			};
			if (filterResult?.formatted) {
				new_filters.and.push(filterResult.formatted);
			}
		} else {
			new_filters = filterResult?.formatted;
		}
		exportCompanies({
			new_filters,
			search: searchName,
			columns: allColumns.filter(({ name }) => !hiddenColumns.includes(name)).reduce(mapColumnFilterIds(selectedColumnEventType, selectedExternalMappingId, granularity, selectedColumnFormId), [])
		});
	};

	React.useEffect(() => {
		const dropdownData: Array<DropdownData<string>> = [
			{ label: translateToString(selectedPath === '/companies' ? 'company.export' : 'company.export_map'), value: 'EXPORT' }
		];
		if (selectedPath === '/companies' && isAllowedTo({ objectAction: 'ReadCompanyNote' })) {
			dropdownData.push({ label: translateToString('exports.export_notes'), value: 'EXPORT_NOTES' });
		}
		props.setToolBarState({
			...childtoolBarState,
			bottomLeftToolbarComponent: <ToolbarBox>
				<ViewPicker
					type='companies'
					noBorder
					selectedView={selectedView}
					views={views.companies}
					onViewChange={view => {
						setSelectedView(view);
						let filters: FilterResult;
						if (view) {
							filters = { ...view.data.filters, formatted: convertFilters(view.data.filters.values) };
							if (view.data.mapTabType) {
								setTabType(view.data.mapTabType);
							}
						} else {
							filters = DEFAULT_FILTER_RESULT;
						}
						setFilterResult(filters);
						const columns = view?.data.columns ?? JSON.parse(localStorage.getItem('company_hidden_columns') || '[]');
						if (columns) {
							setHiddenColumns(columns.filter(l => l.hidden).map(l => l.column));
							setSortColumns(columns.map(l => l.column));
						}
					}}
					onDelete={deleteLocalView}
					onCreate={() => {setSelectedView(undefined); setIsViewOpen(true);}}
					onEdit={() => setIsViewOpen(true)}
					setEditedView={setEditedView}
				/>
				<ToolbarFilterButton
					activeFilters={filterTreeLength(filterResult?.formatted)}
					onClick={() => {
						setOpenSummary(true);
						setFilterOpen(true);
					}}
					onDeleteFilter={() => {
						setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
					}}
				/>
				{(!equal(filterResult?.formatted, selectedView?.data.filters.formatted ?? DEFAULT_FILTER_RESULT.formatted)
				|| !equal(hiddenColumns, selectedView?.data.columns?.filter(l => l.hidden).map(l => l.column) ?? baseHiddenColumns)
				|| !equal(sortColumns, selectedView?.data.columns?.map(l => l.column) ?? baseSortColumns)
				|| !equal(tabType, selectedView?.data.mapTabType ?? 'status')
				) && <SidebarButton onClick={() => {
					if (ref?.current?.hasFiltersOn?.()) {
						alert({
							title: translateToString('map_datas'),
							content: <div><Translate id='map_datas_not_on_view'/></div>,
							gap: '0px',
							buttons: [{ title: translateToString('continue'), res: AlertRes.Ok, style: ButtonStyle.White }]
						}).then(res => {
							if (res === AlertRes.Ok) {
								setIsViewOpen(true);
							}
						});
					} else {
						setIsViewOpen(true);
					}
				}}><Translate id='save_view' /></SidebarButton> }
				{childtoolBarState?.bottomLeftToolbarComponent}
			</ToolbarBox>,
			bottomRightToolbarComponent: <ToolbarBox>
				{selectedPath === '/companies' && 
						<ColumnOrganizer
							categories={Object.keys(COMPANY_COLUMN_CATEGORIES_MAP)}
							columns={hidableColumns ?? []}
							onSort={setSortColumns}
							setHiddenColumns={setHiddenColumns}
							longIcon
						/>
				}
				<Restricted to={{ objectAction: 'ViewMap' }}>
					<Restricted to={{ objectAction: 'ViewCompany' }}>
						<PageSwitch<Pathname> selectedValue={selectedPath} list={[{ value: '/companies', src: CompanyBlackImage, onClick: (path) => {
							if (ref?.current?.hasFiltersOn?.()) {
								alert({
									title: translateToString('map_datas'),
									content: <div><Translate id='map_datas_are_on'/></div>,
									gap: '0px',
									buttons: [{ title: translateToString('continue'), res: AlertRes.Ok, style: ButtonStyle.White }]
								}).then(res => {
									if (res === AlertRes.Ok) {
										setSelectedPath(path);
									}
								});
							} else {
								setSelectedPath(path);
							}
						} }, { value: '/map', src: CompanyMapBlackImage, onClick: setSelectedPath }]} />
					</Restricted>
				</Restricted>
				<InputSearch
					placeholder={translateToString('search')}
					name='search_company_name'
					type='text'
					onChange={(value) => setSearchName(value)}
					delay={500}
				/>
				{childtoolBarState?.bottomRightToolbarComponent}
				<Restricted to={{ objectAction: 'CreateExport' }}>
					<Dropdown
						dropdownStyle={{ optionLeft: '-115px', optionWidth: '160px' }}
						datalist={dropdownData}
						name='options'
						JSXButton={() => <ToolbarImage hasPointer src={optionGrey} />}
						onChange={(value: DropdownData<string>) => {
							switch (value.value) {
								case 'EXPORT': {
									// if (ref?.current?.hasFiltersOn?.()) {
									alert({
										title: translateToString('map_datas'),
										content: <div><Translate id='map_datas_not_on_export'/></div>,
										gap: '0px',
										buttons: [{ title: translateToString('continue'), res: AlertRes.Ok, style: ButtonStyle.White }]
									}).then(res => {
										if (res === AlertRes.Ok) {
											LocalExportCompanies();
										}
									});
									// } else {
									// 	LocalExportCompanies();
									// }
									return;
								}
								case 'EXPORT_NOTES': {
									dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
									exportData(TypeFile.Excel, { type: 'Notes', kind: 'Companies' });
									return;
								}
							}
						}}
					/>
				</Restricted>
			</ToolbarBox>
		});
	}, [views, childtoolBarState, selectedView, filterResult, hiddenColumns, sortColumns, selectedPath, tabType]);

	return <>
		{selectedPath === '/map' && <MapView
			setToolBarState={setChildToolBarState}
			filterResult={filterResult}
			statuses={statuses}
			searchName={searchName}
			ref={ref}
			tabType={tabType}
			setTabType={setTabType}
			tags={tags}
		/>}
		{selectedPath === '/companies' && <Companies
			allCompaniesColumnsAndAdditionalColumns={allCompaniesColumnsAndAdditionalColumns}
			setToolBarState={setChildToolBarState}
			filterResult={filterResult ?? DEFAULT_FILTER_RESULT}
			selectedView={selectedView}
			setSelectedFilter={setSelectedFilter}
			setOpenSummary={setOpenSummary}
			setFilterOpen={setFilterOpen}
			setHiddenColumns={setHiddenColumns}
			hiddenColumns={hiddenColumns}
			setSortColumns={setSortColumns}
			sortColumns={sortColumns}
			allColumns={allColumns}
			statuses={statuses}
			tags={tags}
			eventTypes={eventTypes}
			searchName={searchName}
			shelfAuditTemplates={shelfAuditTemplates}
			setHidableColumns={setHidableColumns}
		/>}
		<PopupView
			isOpen={isViewOpen}
			view={editedView ?? selectedView}
			onClickOut={() => {setIsViewOpen(false); setEditedView(undefined);}}
			onValidation={(v) => {saveView(v).then(_ => setIsViewOpen(false)); setEditedView(undefined);}}
		/>
		<PopupDeletion
			records={1}
			translationKey='view'
			noVerification
			isOpen={deletionModalState.isOpen}
			onClickOut={() => setDeletionModalState({ isOpen: false })}
			onValidation={() => {
				if (deletionModalState.data) {
					deleteView(deletionModalState.data.id).then(_ => getViews().then(setViews));
				}
			}}
		/>
		<AdvancedFilters
			permission='ReadCompany'
			isOpen={isFilterOpen}
			setOpen={setFilterOpen}
			isOpenSummary={isOpenSummary}
			filterList={filterParams}
			filterValues={filterResult?.values}
			onChange={(value) => setFilterResult(value)}
			selectedFilter={selectedFilter}
		/>
	</>;
}

export const mapColumnFilterIds = (selectedColumnEventType: number, selectedExternalMappingId: AtomSelectedExternalMappingSelector, granularity: Granularity, selectedColumnFormId: number) => (acc: FilterId[], elem: { id: FilterId }): FilterId[] => {
	const { id } = elem;
	if (typeof id === 'string') {
		acc.push(id);
	} else if ('frequencies' in id) {
		if (!acc.some(acc_id => typeof acc_id !== 'string' && 'frequencies' in acc_id)) {
			acc.push({ frequencies: { event: selectedColumnEventType, element: id.frequencies.element } });
		}
	} else if ('last_event_date' in id) acc.push({ last_event_date: selectedColumnEventType });
	else if ('next_event_date' in id) acc.push({ next_event_date: selectedColumnEventType });
	else if ('external_id' in id && selectedExternalMappingId.company) acc.push({ external_id: selectedExternalMappingId.company });
	else if ('checkout' in id) {
		if (!acc.some(acc_id => typeof acc_id !== 'string' && 'checkout' in acc_id)) acc.push({ checkout: {
			element: 'evolution',
			granularity
		} });
	} else if ('additional_columns' in id) {
		const ac = acc.find(ac => typeof ac != 'string' && 'additional_columns' in ac);
		if (ac && typeof ac != 'string' && 'additional_columns' in ac) {
			if (!ac.additional_columns.some(ac => ac.id === id.additional_columns[0].id)) {
				ac.additional_columns.push(id.additional_columns[0]);
			}
		} else acc.push(id);
	} else if ('additional_field_columns' in id) {
		const ac = acc.find(ac => typeof ac != 'string' && 'additional_field_columns' in ac);
		if (ac && typeof ac != 'string' && 'additional_field_columns' in ac) ac.additional_field_columns.push(id.additional_field_columns[0]);
		else acc.push(id);
	} else if (isLastFormDateFilter(id)) acc.push({ last_form_date: selectedColumnFormId });
	else if ('calculated_field_columns' in id) {
		const ac = acc.find(ac => typeof ac != 'string' && 'calculated_field_columns' in ac);
		if (ac && typeof ac != 'string' && 'calculated_field_columns' in ac) {
			if (!ac.calculated_field_columns.some(ac => ac.id === id.calculated_field_columns[0].id)) {
				ac.calculated_field_columns.push(id.calculated_field_columns[0]);
			}
		} else acc.push(id);
	}
	return acc;
};
