import * as React from 'react';
import 'reactflow/dist/style.css';
import { COMPONENT_TYPE_LIST, Component, FieldRef, ScreenLink } from '../jsonValidator';
import Input from '../../../components_v2/input/Input';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { getPageList, removeComponent, renamePageAndNextScreens, swapInLayout } from './formProcessingTools';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import * as _ from 'lodash';
import Add from '../../../components_v2/add/Add';
import { DeleteDot } from '../../../styles/global/css/Dot';
import styled from 'styled-components';
import { FlexDiv } from '../../products/style';
import { FormEditorContext, launchToastError } from './visualFormEditor';
import { BlueSidely, LightBlueSidely } from '../../../styles/global/css/Utils';
import { Translate } from '../../../styles/global/translate';
import { AddToLayoutAndFields, EditLayout } from './layoutEditor';
import { Checkbox } from '../../../components_v2/filterList/style/Style';
import { Field, getFields } from '../../../atoms/forms';
import Up from 'images/icons/Up.svg';
import Down from 'images/icons/Down.svg';
export const ComponentWrapper = styled.div`
	padding: 15px;
	border: 1px solid rgba(0, 128, 0, 0.2);
	border-radius: 5px;
	min-height: 100px;
	margin-bottom: 5px;
	margin-top: 5px;
	box-shadow: 2px -2px 2px rgba(0, 128, 0, 0.2);
	display: flex;
  	align-items: center;
	overflow: auto;
`;

export const HighlightField = styled.span`
	background-color: #E8F8FF;
	margin: 2px;
	font-weight: 700;
	border-radius: 7px;
	padding: 3px;
`;

export const ComponentTitle = styled.div`
	font-weight: bold;
	display: inline;
	background-color: ${LightBlueSidely};
`;

export const Wrapper = styled.div<{ warningType?: string }>`
	width: 100%;
	overflow: auto;
	padding: 15px;
	border: ${p => p.warningType ? '2px' : '0.5px'} solid ${p => p.warningType === 'error' ? 'red' : p.warningType === 'warning' ? 'rgba(255, 150, 51, 0.5)' : 'rgba(0, 178, 255, 0.2)' };
	border-radius: 5px;
	margin-bottom: 5px;
	margin-top: 5px;
	box-shadow: 2px -2px 2px rgba(0, 178, 255, 0.2);
`;

export const GroupWrapper = styled.div`
	margin: 10px;
	border: 1px solid rgba(255, 166, 0, 0.2);
	border-radius: 5px;
	margin-bottom: 5px;
	margin-top: 5px;
	box-shadow: 2px -2px 2px rgba(255, 166, 0, 0.2);
`;


export const WarningWrapper = styled.div`
	padding: 15px;
	border: 2px solid rgba(255, 150, 51, 0.5);
	border-radius: 5px;
	margin-bottom: 5px;
	margin-top: 5px;
`;

export const ErrorWrapper = styled.div`
	padding: 15px;
	border: 2px solid red;
	border-radius: 5px;
	margin-bottom: 5px;
	margin-top: 5px;
`;

export const Arrow = styled.span`
&:before {
	content: '-> ';
}
`;

export const WarningMessage = styled(FlexDiv)`
	color: yellow;
`;

export const RemoveLink = styled.span` 
	color: ${BlueSidely};
	text-decoration: underline;
	cursor: pointer;
`;

export const ModeSwitch = styled.div<{ active?: boolean }>`
	background-color: ${p => p.active ? BlueSidely : '#fff'};
	padding: 3px;
	font-size: 13px;
	color: ${p => p.active ? '#fff' : BlueSidely};
	border-radius: 5px;
	&:hover {
		cursor: pointer;
		background-color: ${p => p.active ? BlueSidely : LightBlueSidely};
	}
	transition: .1s;
`;

export function BoolEditor(props: { path: string, pageNumber: number, defaultIsEnabled?: boolean }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [bool, setBool] = React.useState<boolean | undefined>((_.get(form[props.pageNumber], props.path) === undefined) ? props.defaultIsEnabled : _.get(form[props.pageNumber], props.path));

	React.useEffect(() => {
		const newForm = _.cloneDeep(form);
		_.set(newForm[props.pageNumber], props.path, bool);
		setForm(newForm);
	}, [bool]);

	return <>
		<Checkbox
			isActive={
				(_.get(form[props.pageNumber], props.path) === undefined) ? props.defaultIsEnabled : _.get(form[props.pageNumber], props.path)
			}
			onClick={() => setBool(!bool)}
		/>
	</>;
}

function SwitchMode(props: { path: string, pageNumber: number, value: any, nullIsUndefined?: boolean, labelForNull?: string }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [mode, setMode] = React.useState<any>(typeof props.value === 'boolean' ? props.value.toString() : (props.value === null) ? 'null' : 'null');

	React.useEffect(() => {
		const newForm = _.cloneDeep(form);
		_.set(newForm[props.pageNumber], props.path, mode === 'true' ? true : mode === 'false' ? false : (props.nullIsUndefined ? undefined : null));
		setForm(newForm);
	}, [mode]);


	return <>
		<FlexDiv>
			<ModeSwitch onClick={() => setMode('true')} active={mode === 'true'}>Oui</ModeSwitch>
			<ModeSwitch onClick={() => setMode('false')} active={mode === 'false'}>Non</ModeSwitch>
			<ModeSwitch onClick={() => setMode('null')} active={mode === 'null'}>{props.labelForNull ?? 'Non déterminé'}</ModeSwitch>
		</FlexDiv>
	</>;
}

export function MetaDataEditor(props: { path: string, pageNumber: number }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const metadata = _.get(form[props.pageNumber], props.path);
	return <>
		<Wrapper>
			metadata pour le champ : <br />
			{metadata && Object.entries(metadata).map(([key, value]) => {
				return <>
					<FlexDiv>
						<DeleteDot style={{ marginRight: '8px' }} onClick={
							() => {
								const newForm = _.cloneDeep(form);
								_.set(newForm[props.pageNumber], props.path + '.' + key, undefined);
								setForm(newForm);
							}} />
						<span style={{ fontSize: '13px' }}>{key} - </span>
						<SwitchMode path={props.path + '.' + key} pageNumber={props.pageNumber} value={value} />
					</FlexDiv>
					<br />
				</>;
			})}
			<Add
				onClick={
					() => {
						const key = prompt('enter metadata key');
						const value = null;
						if (key) {
							const newForm = _.cloneDeep(form);
							_.set(newForm[props.pageNumber], props.path + '.' + key, value);
							setForm(newForm);
						}
					}}
			/>
		</Wrapper>
	</>;
}

export function DefaultValueEditor(props: { path: string, pageNumber: number, field: Field }): JSX.Element {
	let element: JSX.Element | undefined = undefined;
	switch (props.field.type) {
		case 'Text':
		case 'Select':
		case 'Multiselect':
		case 'TextArea':
			element = <StringEditor path={props.path} pageNumber={props.pageNumber} />;
			break;
		case 'Boolean':
			element = <SwitchMode path={props.path} pageNumber={props.pageNumber} nullIsUndefined value={null} labelForNull='Aucune' />;
			break;
		case 'Number':
		case 'Integer':
			element = <NumberEditor path={props.path} pageNumber={props.pageNumber} />;
			break;
	}
	if (element) {
		return <>
			<Wrapper>
				<Translate id='form_editor.default_value' />
				{element}
			</Wrapper>
		</>;
	} else {
		return <></>;
	}
}


export function JsonToStringEditorWithSaveButton(props: { path: string, pageNumber: number, label: string }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [json, setJson] = React.useState<string | undefined>(JSON.stringify(_.get(form[props.pageNumber], props.path)));

	return <>
		<Input inputStyle={{ backgroundColor: 'white' }} name='json' type='text' value={JSON.stringify(_.get(form[props.pageNumber], props.path))} onChange={
			j => {
				if (j === '') {
					setJson(undefined);
				} else {
					setJson(j);
				}
			}} />
		<button onClick={() => {
			const newForm = _.cloneDeep(form);
			if (json) {
				try {
					_.set(newForm[props.pageNumber], props.path, JSON.parse(json));
				}
				catch (e) {
					launchToastError('Vos modifications ne peuvent pas être sauvegardés à cause d\'une erreur de syntaxe: ' + e);
				}
			} else {
				_.set(newForm[props.pageNumber], props.path, undefined);
			}
			setForm(newForm);
		}
		}>Vérifier la syntaxe du champ {props.label}</button>
	</>;
}

export const SidelyRadioButton = styled.input`
	margin-right: 5px;
	accent-color: hsl(198, 100%, 44%);
	filter: brightness(114%);
`;

export function VisibleIfEditor(props: { path: string, pageNumber: number}): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [conditionType, setConditionType] = React.useState<'filled' | 'true' | 'false' | 'equal' | 'personalized'>();
	const [fieldList, setFielList] = React.useState<Field[]>();
	const fields = form[props.pageNumber].fields;
	const [selectedField, setSelectedField] = React.useState<string>();
	const [equalTo, setEqualTo] = React.useState<string | undefined>();
	const [fieldListDropdown, setFieldListDropdown] = React.useState<DropdownData[]>([{ value: undefined, label: 'Séléctionnez un champ...' }]);


	React.useEffect(() => {
		getFields().then(fl => {
			setFielList(fl);
		});
	}, []);

	React.useEffect(() => {
		if (!fieldList) {
			return;
		}

		fields.map(field => {
			if (field.slug) {
				fieldListDropdown.push({ value: field.slug, label: fieldList?.find(f => f.slug === field.slug)?.name ?? field.slug });
				setFieldListDropdown(fieldListDropdown);
			}
		});

		const visibleIf = _.get(form[props.pageNumber], props.path);

		const filled_pattern = /^current_screen\.fields\[".*"\]\.filled$/;
		const extract_filled_pattern = /^current_screen\.fields\["(.+)"\]\.filled$/;

		const true_pattern = /^current_screen\.fields\[".*"\]\.value == true$/;
		const extract_true_pattern = /^current_screen\.fields\["(.+)"\]\.value == true$/;

		const false_pattern = /^current_screen\.fields\[".*"\]\.value == false$/;
		const extract_false_pattern = /^current_screen\.fields\["(.+)"\]\.value == false$/;

		const equal_pattern = /^current_screen\.fields\[".*"\]\.value == ".*"$/;
		const extract_equal_pattern = /^current_screen\.fields\["(.+)"\]\.value == "(.*)"$/;


		
		if (filled_pattern.test(visibleIf)) {
			setConditionType('filled');
			
			const match = visibleIf.match(extract_filled_pattern);

			if (match && match[1]) {
				setSelectedField(match[1]);
			}
		} else if (true_pattern.test(visibleIf)) {
			setConditionType('true');
			
			const match = visibleIf.match(extract_true_pattern);

			if (match && match[1]) {
				setSelectedField(match[1]);
			}
		}
		else if (false_pattern.test(visibleIf)) {
			setConditionType('false');
			
			const match = visibleIf.match(extract_false_pattern);

			if (match && match[1]) {
				setSelectedField(match[1]);
			}
		}
		else if (equal_pattern.test(visibleIf)) {
			setConditionType('equal');
			
			const match = visibleIf.match(extract_equal_pattern);

			if (match && match[1]) {
				setSelectedField(match[1]);
				setEqualTo(match[2]);
			}
		
		}
		else if (conditionType !== undefined) {
			setConditionType('personalized');
		}
	}, [fieldList]);


	return <>
		{fieldList && <Wrapper>
			Choisir le champ de cette page qui détermine la visibilité de ce champ
			<Dropdown name='field' datalist={fieldListDropdown} selectedValue={fieldListDropdown.find(f => f.value === selectedField)}
				onChange={
					field => {
						if (field.value) {
							setSelectedField(field.value);
							setConditionType(undefined);
						} else {
							setSelectedField(undefined);
							setConditionType(undefined);
							_.set(form[props.pageNumber], props.path, undefined);
						}
					}
				} />

			<FlexDiv><SidelyRadioButton onClick={
				() => {
					setConditionType(undefined);
					const newForm = _.cloneDeep(form);
					_.set(newForm[props.pageNumber], props.path, undefined);
					setForm(newForm);
				}
			} checked={conditionType === undefined} type='radio' /> Toujours visible</FlexDiv>

			{selectedField && <>
				<FlexDiv><SidelyRadioButton disabled={!selectedField} checked={conditionType === 'filled'} onClick={
					() => {
						setConditionType('filled');
						const newForm = _.cloneDeep(form);
						_.set(newForm[props.pageNumber], props.path, 'current_screen.fields["' + selectedField + '"].filled');
						setForm(newForm);
					
					}
				} type='radio' /> Visible si <HighlightField>{fieldList.find(f => f.slug === selectedField)?.name ?? selectedField}</HighlightField> est rempli</FlexDiv>
			</>}
			


			{selectedField && ['Boolean'].includes(fieldList.find(f => f.slug === selectedField)?.type ?? '') && <>
				<FlexDiv><SidelyRadioButton disabled={!selectedField} checked={conditionType === 'true'} onClick={
					() => {
						setConditionType('true');
						const newForm = _.cloneDeep(form);
						_.set(newForm[props.pageNumber], props.path, 'current_screen.fields["' + selectedField + '"].value == true');
						setForm(newForm);
					}
				} type='radio' /> Visible si <HighlightField>{fieldList.find(f => f.slug === selectedField)?.name ?? selectedField}</HighlightField> est à Oui</FlexDiv>
			</>}
			

			{selectedField && ['Boolean'].includes(fieldList.find(f => f.slug === selectedField)?.type ?? '') && <>
				<FlexDiv><SidelyRadioButton disabled={!selectedField} checked={conditionType === 'false'} onClick={
					() => {
						setConditionType('false');
						const newForm = _.cloneDeep(form);
						_.set(newForm[props.pageNumber], props.path, 'current_screen.fields["' + selectedField + '"].value == false');
						setForm(newForm);
					}
				} type='radio' /> Visible si <HighlightField>{fieldList.find(f => f.slug === selectedField)?.name ?? selectedField}</HighlightField> est à Non</FlexDiv>
			</>}
			

			{selectedField && ['Text', 'Date', 'Select', 'Multiselect', 'Url', 'Email', 'Phone', 'Address', 'TextArea'].includes(fieldList.find(f => f.slug === selectedField)?.type ?? '') && <>
				<FlexDiv><SidelyRadioButton onClick={
					() => {
						setConditionType('equal');
					}
				} disabled={!selectedField} type='radio' checked={conditionType === 'equal'} /> Visible si  <HighlightField>{fieldList.find(f => f.slug === selectedField)?.name ?? selectedField}</HighlightField> est égal à
				<Input inputStyle={{ backgroundColor: 'white', width: '100px' }} name='equalTo' type='text' value={equalTo} onChange={
					eq => {
						setEqualTo(eq);
						const newForm = _.cloneDeep(form);
						_.set(newForm[props.pageNumber], props.path, 'current_screen.fields["' + selectedField + '"].value == "' + eq + '"');
						setForm(newForm);
					}
				} />
				</FlexDiv>
			</>}

			{selectedField && ['Number', 'Integer', 'Money'].includes(fieldList.find(f => f.slug === selectedField)?.type ?? '') && <>
				<FlexDiv><SidelyRadioButton onClick={
					() => {
						setConditionType('equal');
					}
				} disabled={!selectedField} type='radio' checked={conditionType === 'equal'} /> Visible si  <HighlightField>{fieldList.find(f => f.slug === selectedField)?.name ?? selectedField}</HighlightField> est égal à
				<Input inputStyle={{ backgroundColor: 'white', width: '100px' }} name='equalTo' type='number' value={equalTo} onChange={
					eq => {
						setEqualTo(eq);
						const newForm = _.cloneDeep(form);
						_.set(newForm[props.pageNumber], props.path, 'current_screen.fields["' + selectedField + '"].value == ' + eq + '');
						setForm(newForm);
					}
				} />
				</FlexDiv>
			</>}

			
			<FlexDiv><SidelyRadioButton checked={conditionType === 'personalized'} onClick={
				() => {
					setConditionType('personalized');
				}
			} type='radio' /> Condition personalisée</FlexDiv>
			{conditionType === 'personalized' && <StringEditor path={props.path} pageNumber={props.pageNumber} />}
		</Wrapper>}
	</>;
}

export function ValidatorEditor(props: { path: string, pageNumber: number }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [conditionType, setConditionType] = React.useState<'mandatory' | 'personalized'>();

	React.useEffect(() => {
		const validator = _.get(form[props.pageNumber], props.path + '.validator');
		const f: FieldRef = _.get(form[props.pageNumber], props.path);

		if (validator === 'current_screen.fields["' + f.slug + '"].filled') {
			setConditionType('mandatory');
		} else if (validator) {
			setConditionType('personalized');
		}
	}, []);

	return <>
		<Wrapper>
			<FlexDiv><SidelyRadioButton onClick={
				() => {
					setConditionType(undefined);
					const newForm = _.cloneDeep(form);
					_.set(newForm[props.pageNumber], props.path + '.validator', undefined);
					setForm(newForm);

				}
			} checked={conditionType === undefined} type='radio' /> Non obligatoire</FlexDiv>
			<FlexDiv><SidelyRadioButton onClick={
				() => {
					setConditionType('mandatory');
					const newForm = _.cloneDeep(form);
					_.set(newForm[props.pageNumber], props.path + '.validator', 'current_screen.fields["' + _.get(form[props.pageNumber], props.path + '.slug') + '"].filled');
					setForm(newForm);
				}
			} checked={conditionType === 'mandatory'} type='radio' /> Obligatoire</FlexDiv>
			<FlexDiv><SidelyRadioButton onClick={
				() => {
					setConditionType('personalized');
				}
			} checked={conditionType === 'personalized'} type='radio' /> Condition personalisée</FlexDiv>
			{conditionType === 'personalized' && <StringEditor path={props.path + '.validator'} pageNumber={props.pageNumber} />}
		</Wrapper>
	</>;
}

export function NextScreenConditionEditor(props: { path: string, pageNumber: number }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [noCondition, setNoCondition] = React.useState<boolean>(_.get(form[props.pageNumber], props.path) === 'true');

	return <>
		<FlexDiv><SidelyRadioButton onClick={
			() => {
				setNoCondition(true);
				const newForm = _.cloneDeep(form);
				_.set(newForm[props.pageNumber], props.path, 'true');
				setForm(newForm);
			}} checked={noCondition} type='radio' /> Aucune condition</FlexDiv>
		<FlexDiv><SidelyRadioButton onClick={
			() => {
				setNoCondition(false);
			}
		} checked={!noCondition} type='radio' /> Condition personalisée</FlexDiv>
		{!noCondition && <StringEditor pageNumber={props.pageNumber} path={props.path} />}
	</>;
}

export function PageNameEditor(props: { path: string, pageNumber: number }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [name, setName] = React.useState<string | undefined>(_.get(form[props.pageNumber], props.path));

	React.useEffect(() => {
		const newForm = _.cloneDeep(form);
		setForm(renamePageAndNextScreens(newForm, _.get(form[props.pageNumber], props.path), name));
	}, [name]);

	return <>
		<Input inputStyle={{ backgroundColor: 'white' }} name='name' type='text' value={_.get(form[props.pageNumber], props.path)} onChange={setName} />
	</>;

}

export function NumberEditor(props: { path: string, pageNumber: number }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [number, setNumber] = React.useState<number | undefined>(_.get(form[props.pageNumber], props.path));

	React.useEffect(() => {
		const newForm = _.cloneDeep(form);
		_.set(newForm[props.pageNumber], props.path, number);
		setForm(newForm);
	}, [number]);

	return <>
		<Input inputStyle={{ backgroundColor: 'white' }} name='number' type='number' value={_.get(form[props.pageNumber], props.path)} onChange={setNumber} />
	</>;
}


export function StringEditor(props: { path: string, pageNumber: number, disabled?: boolean}): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [text, setText] = React.useState<string | undefined>(_.cloneDeep(_.get(form[props.pageNumber], props.path)));

	React.useEffect(() => {
		const newForm = _.cloneDeep(form);
		_.set(newForm[props.pageNumber], props.path, text);
		setForm(newForm);
	}, [text]);

	return <>
		<Input inputStyle={{ backgroundColor: 'white', display: 'inline' }} disabled={props.disabled} name='text' type='text' value={_.get(form[props.pageNumber], props.path)} onChange={setText} />
	</>;
}

export const AddComponent = styled.div`
	background-color: rgba(0, 0, 0, 0.05);
	padding: 10px;
	margin-bottom: 5px;
	margin-top: 5px;

	&:hover {
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.1);
	}
`;

export function ComponentsLibrary(props: { setComponent: (component: Component) => void}): JSX.Element {
	

	return <>
		<Translate id='form_editor.component_library' />
		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'text', name: _.kebabCase(name), data: 'texte a afficher (fonction lua)' });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.text' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'button', name: _.kebabCase(name), data: 'texte du bouton' });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.button' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({
							type: 'card_list', name: _.kebabCase(name), data: { columns: [] } });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.card_list' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'summary', name: _.kebabCase(name), data: [] });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.summary' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({
							type: 'additional_column_qualifier', name: _.kebabCase(name), data: {
								'name': 'rayon',
								'value': 'filter_map_additional_columns(getAdditionalColumns())'
							} });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.additional_column_qualifier' />
		</AddComponent>
		
		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'image', name: _.kebabCase(name), data: 'image url' });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.image' />
		</AddComponent>
		
		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'links', name: _.kebabCase(name), data:[''], options: { display: 'text' } });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.links' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'table', name: _.kebabCase(name), data: { columns: [] } });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.table' />
		</AddComponent>
		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'pagination', name: _.kebabCase(name), data: { name: 'pagination', value: [] } });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.pagination' />
		</AddComponent>

		
		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'catalogue_dispatcher', name: _.kebabCase(name), screen: { screen_name: undefined, condition: 'true' } });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.catalogue_dispatcher' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'product_dispatcher', name: _.kebabCase(name), screen: { screen_name: undefined, condition: 'true' } });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.product_dispatcher' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'campaign_dispatcher', name: _.kebabCase(name), screen: { screen_name: undefined, condition: 'true' }, data: { columns: [] } });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.campaign_dispatcher' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'dispatcher', name: _.kebabCase(name), screen: { screen_name: undefined, condition: 'true' }, data: { name: 'nouvelle-liste', value: '{}' } });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.dispatcher' />
		</AddComponent>

		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'linked_contact', name: _.kebabCase(name) });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.linked_contact' />
		</AddComponent>



		<AddComponent
			onClick={
				() => {
					const name = prompt('name of the component');
					if (name) {
						props.setComponent({ type: 'event_creator', name: _.kebabCase(name) });
					}
				}
			}
		>
			<Translate id='form_editor.component_list.event_creator' />
		</AddComponent>
	</>;

}

export function EditLinks(props: { path: string, pageNumber: number, component: Component }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const links = props.component.data as string[];

	const pages = getPageList(form);
	let pageNames: DropdownData[] = pages.map(page => ({ value: page, label: page }));
	pageNames = [{ value: undefined, label: '---' }, ...pageNames];
	return <>
		<Translate id={'form_editor.component_list.' + props.component.type} />
		<br />
		{links && links.map((link, index) => {
			return <>
				<Dropdown name='link' datalist={pageNames} selectedValue={pageNames.find(p => p.value === link)}
					onChange={link => {
						const newForm = _.cloneDeep(form);
						_.get(newForm[props.pageNumber], props.path + '.data').splice(index, 1, link.value);
						setForm(newForm);
					}}
				/>
			</>;
		})}
	</>;
}

export function ComponentEditor(props: { path: string, pageNumber: number, component: Component }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const component = props.component;
	const pageNumber = props.pageNumber;
	const [fields, setFields] = React.useState<Field[]>();
	
	React.useEffect(() => {
		getFields().then(setFields);
	}, []);

	if (fields) {
		switch (props.component.type) {
			case 'pagination': {
				return <div>
					{component && <EditPaginationComponent pageNumber={pageNumber}
						path={props.path}
						component={component}
					/>}
				</div>;
			}
			case 'catalogue_dispatcher': {
				return <div>
					{component && <EditCatalogueDispatcher pageNumber={props.pageNumber}
						path={props.path}
						component={component}
					/>}
				</div>;
			}
			case 'product_dispatcher': {
				return <div>
					{component && <EditCatalogueDispatcher pageNumber={props.pageNumber}
						path={props.path}
						component={component}
					/>}
				</div>;
			}
			case 'dispatcher': {
				return <div>
					<EditGenericDispatcher pageNumber={props.pageNumber}
						path={props.path}
						component={component} />
				</div>;
			}
			case 'campaign_dispatcher': {
				return <div>
					{component && <EditCatalogueDispatcher pageNumber={props.pageNumber}
						path={props.path}
						component={component}
					/>}
				</div>;
			}
			case 'links': {
				return <div>
					<EditLinks pageNumber={props.pageNumber}
						path={props.path}
						component={component}
					/>
				</div>;
			}
			case 'card_list': {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const columns = (component?.data as any)?.columns;
				return <div>
					Affichage en mode liste de carte
					{columns && columns.map((column, secondIndex) => {
						return <>
							<tr>
								<EditLayout
									path={`${props.path}.data.columns[${secondIndex}]`}
									pageNumber={props.pageNumber}
									layout={column}
									fieldList={fields}
								/>
							</tr>
						</>;
					})}
					<tr>
						<AddToLayoutAndFields path={`${props.path}.data.columns`} pageNumber={props.pageNumber}
							layout={columns} fieldList={fields} />
					</tr>
				</div>;
			}
			case 'summary': {
				return <>
					<Translate id='form_editor.component_list.summary' />
				</>;
			}
			case 'additional_column_qualifier': {
				return <>
					<Translate id='form_editor.component_list.additional_column_qualifier' />
				</>;
			}
			case 'table': {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const columns = (component?.data as any)?.columns;
				return <div>
					Affichage en mode grille
					<table style={{ overflow: 'auto', display: 'block' }}>
						<tr>
							{columns && columns.map((column, secondIndex) => {
								return <>
									<td>
										<EditLayout
											path={`${props.path}.data.columns[${secondIndex}]`}
											pageNumber={props.pageNumber}
											layout={column}
											fieldList={fields}
										/>
									</td>
								</>;
							})}
							<td>
								<AddToLayoutAndFields path={`${props.path}.data.columns`} pageNumber={props.pageNumber}
									layout={columns} fieldList={fields} />
							</td>
						</tr>
																		
					</table>
				</div>;
			}
			case 'button':
			case 'image':
			case 'text': {
				return <>
					<Translate id={'form_editor.component_list.' + component.type} />
					<StringEditor pageNumber={props.pageNumber} path={`${props.path}.data`} />
							
				</>;
			}
			case 'linked_contact': {
				return <>
						Ajouter un contact
				</>;
			}
			case 'event_creator': {
				return <>
						Ajouter un événement
				</>;
			}

			default: {
				if (COMPONENT_TYPE_LIST.includes(component.type)) {
					return <div>
						<Wrapper>
							<Arrow><Translate id='form_editor.not_valid.the_component' /> ``{component.type} (<Translate id='name' />: {component.name})`` <Translate id='form_editor.not_valid.not_supported_yet' /></Arrow>
						</Wrapper>
					</div>;
				}
				return <div>
					<ErrorWrapper>
						<Translate id='form_editor.not_valid.the_component' /> ``{component.type} (<Translate id='name' />: {component.name})`` <Translate id='form_editor.not_valid.is_not_a_valid_composent_you_should' /><RemoveLink
							onClick={() => {
								if (component.name) {
									const newForm = _.cloneDeep(form);
									setForm(removeComponent(newForm, pageNumber, component.name));
								}
							}}
						><Translate id='form_editor.not_valid.remove_it' /></RemoveLink>
					</ErrorWrapper>
				</div>;
			}
		}
	}
	else {
		return <>loading...</>;
	}
}


export interface PaginationData {
	name: string
	value: PaginationDataValue[]
  }
  
export interface PaginationDataValue {
	name: string
	screen: ScreenLink
  }

export function EditNextScreen(props: { path: string, pageNumber: number, component: ScreenLink, index?: number }): JSX.Element {
	const [nextPage, setNextPage] = React.useState<string | undefined>(props.component.screen_name);
	const [calculatedNextPage] = React.useState<string | undefined>(props.component.calculated_screen_name);
	const [calculated, setCalculated] = React.useState<boolean>(props.component.calculated_screen_name !== undefined);
	const [debouncedNextPage, setDebouncedNextPage] = React.useState<string | undefined>(props.component.screen_name);

	const { form, setForm } = React.useContext(FormEditorContext);

	React.useEffect(() => {
		const timeout = setTimeout(() => setDebouncedNextPage(nextPage), 50);
		return () => clearTimeout(timeout);
	}, [nextPage]);

	React.useEffect(() => {
		const newForm = _.cloneDeep(form);
		const screen_name_path = props.path + '.screen_name';

		_.set(newForm[props.pageNumber], screen_name_path, debouncedNextPage);
		setForm(newForm);
	}, [debouncedNextPage]);


	const pages = getPageList(form);
	let pageNames: DropdownData[] = pages.map(page => ({ value: page, label: page }));
	pageNames = [{ value: undefined, label: '---' }, ...pageNames];

	return <>
		{(!form.find(screen => screen.name == props.component.screen_name)) && (props.pageNumber > 0) && props.component.screen_name !== undefined && <WarningMessage>La page séléctionnée n&lsquo;existe pas</WarningMessage>}
		{(props.index !== undefined) && (props.index > 0) && <>
			Bouton suivant n°{props.index} <br />
		</>}
		<FlexDiv>
			<Checkbox isActive={calculated} onClick={() => {
				if (calculated) {
					const newForm = _.cloneDeep(form);
					_.set(newForm[props.pageNumber], props.path + '.calculated_screen_name', undefined);
					setForm(newForm);
				}
				else {
					const newForm = _.cloneDeep(form);
					_.set(newForm[props.pageNumber], props.path + '.calculated_screen_name', calculatedNextPage ?? '');
					setForm(newForm);
				}
				setCalculated(!calculated);
			}
			}
			/>
			<> Prochaine page est calculée</>
		</FlexDiv>
		{calculated && <>
			<StringEditor pageNumber={props.pageNumber} path={props.path + '.calculated_screen_name'} />
		</>}

		{!calculated && <>
			<Dropdown name='next-screen-name' datalist={pageNames} selectedValue={pageNames.find(p => p.value === props.component.screen_name)}
				onChange={screenName => {
					setNextPage(screenName.value);
				}}
			/>
		</>}
		<Translate id='form_editor.condition' />
		<NextScreenConditionEditor path={props.path + '.condition'} pageNumber={props.pageNumber} />

	</>;
}
export function EditNextScreens(props: { path: string, pageNumber: number, component: ScreenLink[]}): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);

	return <>
		{props.component.filter(c => c !== undefined).map((nextScreen, index) => {
			return <>
				<EditNextScreen key={index} path={`${props.path}[${index}]`} pageNumber={props.pageNumber} component={nextScreen} index={index} />
				{props.component.length > 1 && <DeleteDot key={index} onClick={() => {
					const newForm = [...form];
					const nextScreen = _.get(newForm[props.pageNumber], `next_screens.[${index}]`);
					if (nextScreen) {
						_.remove(newForm[props.pageNumber].next_screens, nextScreen);
					}
					setForm(newForm);
				}
				} />}
			</>;
		})
		}
		<Add onClick={() => {
			const newForm = [...form];
			_.get(newForm[props.pageNumber], props.path).push({ screen_name: undefined, condition: 'true' });
			setForm(newForm);
		}} />
	</>;
}

export function EditGenericDispatcher(props: { path: string, pageNumber: number, component: Component }): JSX.Element {
	return <>
		<Translate id={'form_editor.component_list.' + props.component.type} />
		<br />
		{props.component.screen && <EditNextScreen path={`${props.path}.screen`} pageNumber={props.pageNumber} component={props.component.screen} />}
		nom: {props.component.data && <StringEditor pageNumber={props.pageNumber} path={props.path + '.data.name'} />}
		valeur: {props.component.data && <StringEditor pageNumber={props.pageNumber} path={props.path + '.data.value'} />}
	</>;
}

export function EditCatalogueDispatcher(props: { path: string, pageNumber: number, component: Component }): JSX.Element {
	return <>
		<Translate id={'form_editor.component_list.' + props.component.type} />
		<br />
		<Wrapper>
			Clic sur élément de la liste mène vers:
			{props.component.screen && <EditNextScreen path={`${props.path}.screen`} pageNumber={props.pageNumber} component={props.component.screen} />}
		</Wrapper>

	</>;
}

export function EditPaginationComponent(props: { path: string, pageNumber: number, component: Component }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const paginationData = props.component.data as PaginationData;

	return <>
		<ComponentTitle>
			{props.component.name}
			<br />
		</ComponentTitle>
			Type: <Translate id='form_editor.component_list.pagination' />
		<br />
		{paginationData && paginationData.value.map((value, index) => {
			return <>
				<FlexDiv>
					<Wrapper>
						<Translate id='name' />:<StringEditor pageNumber={props.pageNumber} path={`${props.path}.data.value[${index}].name`} />
				Mène vers:
						<EditNextScreen path={`${props.path}.data.value[${index}].screen`} pageNumber={props.pageNumber} component={value.screen} />
						{paginationData.value.length > 1 && <DeleteDot onClick={() => {
							const newForm = _.cloneDeep(form);
							_.get(newForm[props.pageNumber], props.path + '.data.value').splice(index, 1);
							setForm(newForm);
						}
						} />}
					</Wrapper>
					{index > 0 && <img src={Up} onClick={() => {
						setForm(swapInLayout(form, props.pageNumber, props.path + '.data.value[' + index + ']', -1));
					}} />}
					{index < paginationData.value.length - 1 && <img src={Down} onClick={() => {
						setForm(swapInLayout(form, props.pageNumber, props.path + '.data.value[' + index + ']', 1));
					}} />}
				</FlexDiv>
			</>;
		})}

		<Add onClick={() => {
			const newForm = _.cloneDeep(form);
			_.get(newForm[props.pageNumber], props.path + '.data.value').push({ name: 'none', screen: { screen_name: undefined, condition: 'true' } });
			setForm(newForm);
		}} />
	</>;

}