import * as React from 'react';
import { Editor } from '../../reports/generic/genericEditorRecursive';
import { RawDatePicker, OnDateChangeType } from '../../../components_v2/toolbarFilter/time/DatePicker';
import * as moment from 'moment';
import { Translate, translateToString } from '../../../styles/global/translate';
import Popup from '../../../components_v2/popup/Popup';
import styled from 'styled-components';
import { BorderColor, SidelyBlack, DarkGreySidely2 } from '../../../styles/global/css/Utils';
import { ReportFiltersContext } from '../../reports/generic/generic';
import { FlexDiv } from '../../products/style';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { translateToNode } from '../../../styles/global/translate';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import { BeginPeriods, EndPeriods, GenericEditorContext, useSetFilters } from '../../reports/generic/genericEditor';
import { momentToNaiveDateTime } from '../../../components_v2/utils';
import { TitleAndChild, LineHoverDiv } from '../../client-companies/popup/Detail';
import Input from '../../../components_v2/input/Input';
import { initFilters, getFinalyFilteredProducts } from '../../reports/generic/generic';
import { CalculatedField, CalculatedFieldTarget } from '../../../atoms/calculatedFields';
import { AProducts } from '../../../atoms/product';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { TimeRange } from 'bindings/reports/generic/TimeRange';

const CalcFieldTitle = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	color: ${SidelyBlack};
	gap: 15px;
	padding: 0 10px 10px 10px;
	font-size: 16px;
	font-weight: 600;
	justify-content: space-between;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 4%;
		width: 92%;
		border-bottom: 2px solid ${BorderColor};
	}
`;

const OptionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 50%;
	height: 100%;
	padding: 20px 15px 0 0;
	border-right: 2px solid ${BorderColor};
	overflow-x: visible;
`;

const SaveButton = styled(DefaultButton)`
	width: 7vw;
	margin: 5px;
`;

const FiltersWrapper = styled(LineHoverDiv)`
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding-left: 20px;
	width: 97%;
`;

const MandatoryField = styled.p`
	color: ${DarkGreySidely2};
	font-size: 13px;
    &:before {
		color: red;
		margin-right: 3px;
        content: "*";
    }
`;


export function CalculatedFieldsCreation(props: { 
		isOpen: boolean,
		setIsOpen: (value: boolean) => void,
		setCalculatedField: (value: CalculatedField | null) => void,
		calculatedField: CalculatedField | null,
	}) {
	const { isOpen, setIsOpen, setCalculatedField, calculatedField } = props;
	const { report, setReport } = React.useContext(GenericEditorContext);
	const [calcFieldName, setCalcFieldName] = React.useState<string>(calculatedField?.field_name || '');
	const trimmedCalcFieldName = calcFieldName.trim();
	const [dateRange, setDateRange] = React.useState<TimeRange>(calculatedField?.report?.range ? {
		begin: calculatedField.report.range.begin,
		end: calculatedField.report.range.end
	} : { begin: '90DaysAgo', end: 'Today' });
	const { filters, jsxFilters } = React.useContext(ReportFiltersContext);
	const owners = useRecoilValue(AUsers);
	const [columnSelected, setColumnSelected] = React.useState<boolean>(false);
	const [defaultFilters, setDefaultFilters] = React.useState<string | null>(null);
	const products = useRecoilValue(AProducts);

	const BeginPeriodTypes: DropdownData[] = [];
	const EndPeriodTypes: DropdownData[] = [];

	BeginPeriodTypes.push({ value: undefined, label: translateToString('report_editor.period.begin_default') });
	EndPeriodTypes.push({ value: undefined, label: translateToString('report_editor.period.end_default') });
	
	BeginPeriods.map(e => (BeginPeriodTypes.push({ value: e, label: translateToString('report_editor.period.' + e) })));
	EndPeriods.map(e => (EndPeriodTypes.push({ value: e, label: translateToString('report_editor.period.' + e) })));


	React.useEffect(() => {
		if (calculatedField) {
			const { filters } = initFilters(calculatedField.report);
			setDefaultFilters(JSON.stringify(filters));
		}
	}, [calculatedField]);

	function hasChangesCreate(): boolean {
		console.log('columnSelected', columnSelected, 'trimmedCalcFieldName', trimmedCalcFieldName, 'dateRange.begin', dateRange.begin, 'dateRange.end', dateRange.end);
		return columnSelected && trimmedCalcFieldName.length > 0 && dateRange.begin !== null && dateRange.end !== null;
	}

	function hasChangesEdit(): boolean {
		if (!calculatedField) return false;
		return dateRange.begin !== null && dateRange.end !== null && trimmedCalcFieldName.length > 0 
			&& (columnSelected || calculatedField.field_name !== trimmedCalcFieldName || defaultFilters !== JSON.stringify(filters) ||
				calculatedField.report.range?.begin !== dateRange.begin || 
				calculatedField.report.range?.end !== dateRange.end);
	}

	const unlockSave = calculatedField ? hasChangesEdit() : hasChangesCreate();

	React.useEffect(() => {
		if (isOpen) {
			if (calculatedField && calculatedField.report) {
				setReport(calculatedField.report);
				setCalcFieldName(calculatedField.field_name);
				if (calculatedField.report.range) {
					setDateRange({
						begin: calculatedField.report.range.begin,
						end: calculatedField.report.range.end
					});
				}
			}
			else if (!calculatedField) {
				setCalcFieldName('');
				setDateRange({ begin: '90DaysAgo', end: 'Today' });
				setReport({
					'group_by': 'Company',
					'panels': [
						{ 'columns': [], products: undefined, name: '' }
					],
					'range': { begin: dateRange.begin ?? '90DaysAgo', end: dateRange.end ?? 'Today' },
					'window': null,
					'evolution': null,
					'variation': null,
					'filter': null
				});
			}
		}
	}, [isOpen]);

	useSetFilters(setReport, report, filters, owners);

	React.useEffect(() => {
		if (dateRange.begin && dateRange.end)
			setReport({
				...report,
				range: { begin: dateRange.begin, end: dateRange.end },
			});
	}, [dateRange]);

	return <>
		<Popup isOpen={isOpen} hasBackground disableOutClick onClickOut={() => setIsOpen(false)} 
			popupStyle={{ top: '0', left: '50vw', height: '100vh', width: '50vw', animate: true, boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.06)' }}
			content={<>
				<CalcFieldTitle>
					<div style={{ padding: '0 0 0 15px' }}><Translate id={calculatedField ? 'calculated_fields.edit' : 'calculated_fields.create'}/></div>
					<FlexDiv >
						<SaveButton
							buttonStyle={ButtonStyle.White}
							onClick={() => {
								setCalculatedField(null);
								setCalcFieldName('');
								setColumnSelected(false);
								setIsOpen(false);
							}}>
							{translateToNode('cancel')}
						</SaveButton>
						<SaveButton
							disabled={!unlockSave}
							onClick={() => {
								setCalcFieldName('');
								setColumnSelected(false);
								report.product_filter = getFinalyFilteredProducts(filters, products);
								setCalculatedField({
									id: calculatedField ? calculatedField.id : { unsync: Date.now() },
									target: CalculatedFieldTarget.Company,
									field_name: trimmedCalcFieldName,
									report: report
								});
								setIsOpen(false);
							}}>
							{translateToNode('save')}
						</SaveButton>
					</FlexDiv>
				</CalcFieldTitle>
				<FlexDiv width='100%' height='100%' align='stretch' padding='0px 10px 20px 10px'>
					<OptionsWrapper>
						<FlexDiv align='stretch' flow='column' gap='10px' padding='0 0 0 15px'>
							<FlexDiv flow='column' align='stretch' gap='8px'>
								<MandatoryField><Translate id='calculated_fields.field_name'/></MandatoryField>
								<Input 
									name={'calc_field_name'}
									type={'text'} 
									value={calcFieldName}
									onChange={(value) => setCalcFieldName(value)}
									inputStyle={{ 
										height: '40px', 
										width: '20vw', 
										padding: '0 0 0 10px',
										borderRadius: '5px' }}/>
							</FlexDiv>
							<FlexDiv flow='column' align='stretch' gap='8px'>
								<MandatoryField><Translate id='calculated_fields.field_period' /></MandatoryField>
								<Translate id='report_editor.period.title' /> début
								<Dropdown
									name='range'
									dropdownStyle={{ width: '200px', optionWidth: '200px' }}
									datalist={BeginPeriodTypes}
									selectedValue={BeginPeriodTypes.find(a => {
										if (report.range?.begin && report.range?.begin !== '90DaysAgo') {
											return a.value === report.range?.begin;
										} else {
											return a.value === undefined;
										}
									})
									}
									onChange={value => {
										setDateRange({ ...dateRange, begin: value.value ?? '90DaysAgo', end: report.range?.end ?? 'Today' });
									}}
								/>

								<Translate id='report_editor.period.title' /> fin
								<Dropdown
									name='range'
									dropdownStyle={{ width: '200px', optionWidth: '200px' }}
									datalist={EndPeriodTypes}
									selectedValue={EndPeriodTypes.find(a => {
										if (report.range?.begin && report.range?.end !== 'Today') {
											return a.value === report.range?.end;
										} else {
											return a.value === undefined;
										}
									})
									}
									onChange={value => {
										setReport({ ...report, range: { end: value.value ?? 'Today', begin: report.range?.begin ?? '90DaysAgo' } });
									}}
								/>
								{/* <RawDatePicker future={true} start={dateRange.startDate} end={dateRange.endDate} onDatesChange={(dates) => setDateRange(dates)} /> */}
							</FlexDiv>
						</FlexDiv>
						<TitleAndChild
							title={translateToString('calculated_fields.data_filters')}
							margin='0 0 0 0'
							addDiv
							defaultOpen={true}
							localCollapseStyle={{ justifyContent: 'left' }}>
							<FiltersWrapper>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.form_instances'/>
									{jsxFilters && jsxFilters[0] && jsxFilters[0][1].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.form_models'/>
									{jsxFilters && jsxFilters[1] && jsxFilters[1][1].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.form_creator'/>
									{jsxFilters && jsxFilters[1] && jsxFilters[1][0].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch'>
									<div style={{ padding:'0 0 8px 0' }}><Translate id='report_editor.filter_categories.product_categories'/></div>
									{jsxFilters && jsxFilters[2] && jsxFilters[2][0].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.brands'/>
									{jsxFilters && jsxFilters[2] && jsxFilters[2][1].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.product_tags'/>
									{jsxFilters && jsxFilters[2] && jsxFilters[2][2].child}
								</FlexDiv>
							</FiltersWrapper>
						</TitleAndChild>
					</OptionsWrapper>
					<div style={{ width: '50%', height: '97%', paddingTop: '10px' }}>
						<Editor
							column={calculatedField ? calculatedField.report.panels[0].columns[0] : undefined}
							path={'panels[0].columns'}
							index={0}
							onSave={() => {setColumnSelected(true);}}
							isCalcField
						/> 
					</div>
				</FlexDiv>
			</>}/>
	</>;
}