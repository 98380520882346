import equal from 'deep-equal';
import SettingIcon from 'images/icon/setting.png';
import DragAndDropImage from 'images/icons/drag_and_drop_dots.svg';
import lock from 'images/icons/lock.svg';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Prompt } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { AAdditionalColumns, ADDITIONAL_COLUMN_TYPE_LST, AdditionalColumn as GlobalAdditionalColumn, getAdditionalColumns } from '../../../atoms/additionalColumns';
import { AAdditionalFieldColumns, AdditionalFieldColumn as GlobalAdditionalFieldColumn, getAdditionalFieldColumns } from '../../../atoms/additionalFieldColumns';
import { AColors } from '../../../atoms/colors/colors';
import { AFormFields } from '../../../atoms/forms';
import Add from '../../../components_v2/add/Add';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownColor from '../../../components_v2/dropdown/DropdownColors';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { blurOnEnterPressed } from '../../../components_v2/input/Input';
import Popup from '../../../components_v2/popup/Popup';
import PopupCreation, { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import { Arrow } from '../../../components_v2/table/style/Style';
import { TagType, tagTypeToAdditionalFieldColumnType } from '../../../components_v2/toolbarFilter/tags/Model';
import { getTags } from '../../../components_v2/toolbarFilter/tags/action';
import { diff } from '../../../components_v2/utils';
import { DeleteDot } from '../../../styles/global/css/Dot';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { BlueSidely, BorderColor, FilterBlue, SidelyBlack } from '../../../styles/global/css/Utils';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { LoadingStateEnum } from '../../import/model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { getOpportunityStatuses } from '../../opportunities/data/Data';
import { getOrderStatuses } from '../../orders/data/Data';
import { FlexDiv } from '../../products/style';
import { getClientStatuses, syncTagsAndStatuses } from './actions';
import { SystemSettingsWrapper, SystemSettingTitle } from './styles';
import Restricted from '../../permissions/Restricted';
import usePermission from '../../permissions/usePermission';
import { ModalState } from '../../products/model';
import { CreationPopupContainer } from '../../client-companies/style/Style';
import { SelectMetaData } from '../../forms/PopupField';
import { CalculatedFieldsCreation } from './CalculatedFieldsCreation';
import { ReportFiltersProvider } from '../../reports/generic/generic';
import { GenericEditorProvider } from '../../reports/generic/genericEditor';
import { CalculatedField as GlobalCalulatedField, getCalculatedFields, ACalculatedFields } from '../../../atoms/calculatedFields';
import RestrictedSuperAdmin from '../../permissions/RestrictedSuperAdmin';
import VisibilityOnIcon from 'images/reports/visibility_on.svg';
import VisibilityOffIcon from 'images/reports/visibility_off.svg';
import { isOwner, useMe } from '../../../atoms/global/users';
import { launchToastMessage } from '../../forms/visual-form-editor/visualFormEditor';

export type Id = number | { unsync: number }

type Status<T = unknown> = T & { deleted?: boolean, color_code: string, id: Id, name?: string, sequence?: number, locked?: boolean, probability?: number };
type Tag<T = unknown> = T & { deleted?: boolean, id: Id, name?: string, color: string };
type Color<T = unknown> = T & { colorCode: string, id: number };
type AdditionalColumn = Pick<GlobalAdditionalColumn, 'type' | 'data' | 'ishidded'> & {
	id: Id,
	name?: string,
	deleted?: boolean
}
type AdditionalFieldColumn = Pick<GlobalAdditionalFieldColumn, 'field_id' | 'field_name'> & {
	id: Id,
	deleted?: boolean
}

type CalculatedField = Omit<GlobalCalulatedField, 'id'> & { id: Id };

const StatusWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const StatusContainer = styled.div<{ unsync: boolean, width?: string }>`
	display: flex;
	width: ${p => p.width ?? '350px'};
	height: 45px;
	align-items: center;
	gap: 20px;
	border: 1px ${p => p.unsync ? 'dashed' : 'solid' } ${BorderColor};
	border-radius: 5px;
	padding: 0 2rem;
	background-color: white;
	position: relative;

`;

const TagContainer = styled(StatusContainer)<{ borderColor?: string }>`
	padding: 5px 10px 5px 15px;
	gap: 10px;
	width: fit-content;
	height: unset;
	border-color: ${p => p.borderColor ?? BorderColor};
`;

export const DandDDots = styled.div`
	width: 1rem;
	height: 100%;
	background-image: url('${DragAndDropImage}');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 50%;
`;

const TextStatusWrapper = styled.div`
	width: inherit;
	white-space: nowrap;
	overflow: hidden;
	cursor: text;
`;

const TextTagWrapper = styled(TextStatusWrapper)`
	width: fit-content;
`;

const Lock = styled.img`
	width: 25px;
	height: 25px;
	cursor: not-allowed;
`;

const StatusProbabilityWrapper = styled.div<{ locked?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	${p => p.locked ? 'width: 5em;' : `
		width: 6em;
		padding: 0 1em;
		border-bottom: 1px solid ${SidelyBlack};
	`}
`;

const InputStatusName = styled.input<{ unsync: boolean, nameLength: number, disabled?: boolean }>`
	border: none;
	border-bottom: 1px solid transparent;
	&:hover {
		border-color: ${BlueSidely};
	}
	&:focus {
		border-color: ${BlueSidely};
	}
	width: ${p => p.nameLength + 1}ch;
	min-width: 20ch;
	${p => p.unsync ? 'opacity: 80%;' : ''}
	&:read-only {
		border-color: transparent;
	}
	background-color: ${p => p.disabled ? 'transparent' : 'white'};
`;

const InputTagName = styled(InputStatusName)`
	min-width: 12ch;
`;

export const SaveButton = styled(DefaultButton)`
	width: 90px;
	margin: 5px;
`;

const SettingIconDiv = styled.img`
	height: 30px;
	cursor: pointer;
`;

const BlueImg = styled.img`
	filter: ${FilterBlue};
	cursor: pointer;
`;

const GreyImg = styled.img`
	opacity: 0.4;
	cursor: pointer;
`;

const TextButton = styled.button`
	background: none;
	border: none;
	text-decoration: underline;
`;

const tagTypeToString = (type: TagType): string => {
	switch (type) {
		case TagType.COMPANY: return 'company';
		case TagType.CONTACT: return 'contact';
		case TagType.OPPORTUNITY: return 'opportunity';
		case TagType.ORDER: return 'order';
		case TagType.PRODUCT: return 'product';
	}
};

export type CreationEditionOrDeletion<A extends object = object, B extends object = object> = { Creation: Omit<A, 'id'> } | { Edition: { id: number, value: Omit<B, 'id'> } } | { Deletion: number }

export function valueToCreationEditionOrDeletion<T extends { id: Id, deleted?: boolean}>(value: T): CreationEditionOrDeletion<T, T> {
	if (value.deleted && typeof value.id === 'number') {
		return {
			Deletion: value.id
		};
	}
	if (value.deleted) throw 'deletion of uncreated value';
	if (typeof value.id === 'number') {
		return {
			Edition: {
				id: value.id,
				value: { ...value, id: undefined }
			}
		};
	}
	return {
		Creation: { ...value, id: undefined }
	};
}

async function saveChanges(statusDifferences: Status[], tagDifferences: Tag[], colors: Color[], field_type: TagType, additionalColumnDifferences: AdditionalColumn[], additionalFieldColumnDifferences: AdditionalFieldColumn[], calculatedFieldsDifferences: CalculatedField[]): Promise<void> {
	function convert<T extends(object & { id: Id })>(t: T) {
		const value = { ...t };
		if ('color_code' in t && t.color_code) {
			value['color_status_id'] = colors.find(c => c.colorCode === t.color_code)?.id;
		} else if ('color' in t && t.color) {
			value['color_status_id'] = colors.find(c => c.colorCode === t.color)?.id;
		}
		if ('name' in t && t.name && typeof t.name === 'string') {
			value['name'] = t.name.trim();
		}
		return valueToCreationEditionOrDeletion(value);
	}

	return syncTagsAndStatuses({
		statuses: statusDifferences.map(convert),
		tags: tagDifferences.map(convert),
		additional_columns: additionalColumnDifferences.map(convert),
		additional_field_columns: additionalFieldColumnDifferences.map(afc => valueToCreationEditionOrDeletion({ ...afc, field_name: undefined, type: tagTypeToAdditionalFieldColumnType(field_type) })),
		calculated_fields: calculatedFieldsDifferences.map(convert),
		field_type
	});
}

function zipById<A extends unknown & { id: Id }, B extends unknown & { id: Id }>(a: A[], b: B[]): [A | undefined, B | undefined][] {
	const base: [A | undefined, B | undefined][] = a.map(a_child => [a_child, b.find(b => b.id === a_child.id)]);
	b.forEach(b_child => {
		if (base.some(base_child => base_child[1]?.id === b_child.id)) return;
		base.push([undefined, b_child]);
	});
	return base;
}

export function getIdListDifferences<T extends unknown & { id: Id }>(a: T[], b: T[]): T[] {
	return zipById(a, b)
		.reduce((acc, [a, b]) => {
			const differences = diff(a, b);
			if (!differences || equal(differences, {})) return acc;
			return [...acc, { ...differences, id: a?.id ?? b?.id }];
		}, []);
}

export default function StatusAndTagsSettings(props: {
	type: TagType,
	setToolBarState: (state: ToolbarState) => void
}): JSX.Element {
	const [calculatedFields, setCalculatedFields] = React.useState<string>(JSON.stringify({
		'group_by': 'Company',
		'panels': [
			{ 'columns': [],	products: undefined, name: '' }
		],
		'range': null,
		'window': null,
		'evolution': null,
		'variation': null,
		'filter': null
	}));
	
	return <>
		<GenericEditorProvider report={calculatedFields} setReport={setCalculatedFields}>
			<ReportFiltersProvider genericReport={JSON.parse(calculatedFields)}>
				<_StatusAndTagsSettings {...props} />
			</ReportFiltersProvider>
		</GenericEditorProvider>
	</>;
}

function copyToClipboard(id: number | string) {
	navigator.clipboard.writeText(id.toString());
	launchToastMessage('Id copié dans le presse-papier');
}

function _StatusAndTagsSettings(props: {
	type: TagType,
	setToolBarState: (state: ToolbarState) => void
}): JSX.Element {
	const [subSettings, setSubSettings] = React.useState<Array<'tags' | 'statuses' | 'additional_columns' | 'additional_field_columns' |'report_fields' | 'calculated_fields'>>([]);
	const [tags, setTags] = React.useState<Tag[]>([]);
	const [defaultTags, setDefaultTags] = React.useState<Tag[]>([]);
	const [statuses, setStatuses] = React.useState<Status[]>([]);
	const [defaultStatuses, setDefaultStatuses] = React.useState<Status[]>([]);

	const [additionalColumns, setAdditionalColumns] = React.useState<AdditionalColumn[]>([]);
	const [defaultAdditionalColumns, setDefaultAdditionalColumns] = React.useState<AdditionalColumn[]>([]);
	const setAdditionalColumnsAtom = useSetRecoilState(AAdditionalColumns);

	const [additionalFieldColumns, setAdditionalFieldColumns] = React.useState<AdditionalFieldColumn[]>([]);
	const [defaultAdditionalFieldColumns, setDefaultAdditionalFieldColumns] = React.useState<AdditionalFieldColumn[]>([]);
	const fields = useRecoilValue(AFormFields);
	const colors = useRecoilValue(AColors);
	const [loadingState, setLoadingState] = React.useState(LoadingStateEnum.LOADING);

	const [additionalColumnSettingState, setAdditionalColumnSettingState] = React.useState<ModalState<AdditionalColumn>>({ isOpen: false });

	const setAdditionalFieldColumnsAtom = useSetRecoilState(AAdditionalFieldColumns);

	const setCalculatedFieldsAtom = useSetRecoilState(ACalculatedFields);
	const [isCalculatedFieldsOpen, setIsCalculatedFieldsOpen] = React.useState(false);
	const [calculatedFields, setCalculatedFields] = React.useState<CalculatedField[]>([]);
	const [newCalculatedField, setNewCalculatedField] = React.useState<CalculatedField | null>(null);
	const [defaultCalculatedFields, setDefaultCalculatedFields] = React.useState<CalculatedField[]>([]);
	const [isEditMode, setIsEditMode] = React.useState(false);

	const status_differences = getIdListDifferences(defaultStatuses, statuses);
	const tag_differences = getIdListDifferences(defaultTags, tags);
	const additional_column_differences = getIdListDifferences(defaultAdditionalColumns, additionalColumns);
	const additional_field_column_differences = getIdListDifferences(defaultAdditionalFieldColumns, additionalFieldColumns);
	const calculated_field_differences = getIdListDifferences(defaultCalculatedFields, calculatedFields);
	const hasDifferences = status_differences.length !== 0 || tag_differences.length !== 0 || additional_column_differences.length != 0 || additional_field_column_differences.length != 0 || calculated_field_differences.length != 0;
	const me = useMe();

	React.useEffect(() => {
		props.setToolBarState({
			bottomRightToolbarComponent: <Restricted to={{ objectAction: 'CreateCompanySettings' }}>
				<SaveButton
					buttonStyle={ButtonStyle.White}
					disabled={!hasDifferences}
					onClick={() => {
						setStatuses(JSON.parse(JSON.stringify(defaultStatuses)));
						setTags(JSON.parse(JSON.stringify(defaultTags)));
						setAdditionalColumns(JSON.parse(JSON.stringify(defaultAdditionalColumns)));
						setAdditionalFieldColumns(JSON.parse(JSON.stringify(defaultAdditionalFieldColumns)));
					}}>
					{translateToNode('cancel')}
				</SaveButton>
				<SaveButton
					disabled={!hasDifferences
						|| tags.some(t => (!t.name || t.name.trim().length == 0) && !t.deleted)
						|| statuses.some(s => (!s.name || s.name.trim().length == 0) && !s.deleted)
						|| additionalColumns.some(ac => (!ac.name || ac.name.trim().length == 0 || ((ac.type === 'MultiSelect' || ac.type === 'Select') && (!ac.data || ac.data.length == 0 || ac.data.some(n => !n || n.trim().length == 0)))) && !ac.deleted)
					}
					onClick={() => {
						setLoadingState(LoadingStateEnum.LOADING);
						saveChanges(status_differences, tag_differences, colors, props.type, additional_column_differences, additional_field_column_differences, calculated_field_differences)
							.then(fetchDatas)
							.catch(() => {
								setLoadingState(LoadingStateEnum.ERROR);
							});
					}}>
					{translateToNode('save')}
				</SaveButton>
			</Restricted>
		});
	}, [status_differences, tag_differences, additional_column_differences, colors, props.type, defaultStatuses, defaultTags, defaultAdditionalColumns, defaultAdditionalFieldColumns, hasDifferences]);

	async function fetchDatas(): Promise<void> {
		setLoadingState(LoadingStateEnum.LOADING);
		setTags([]);
		setStatuses([]);
		setAdditionalColumns([]);
		setAdditionalFieldColumns([]);
		setDefaultTags([]);
		setDefaultStatuses([]);
		setDefaultAdditionalColumns([]);
		setDefaultAdditionalFieldColumns([]);
		switch (props.type) {
			case TagType.COMPANY: {
				const statuses = await getClientStatuses();
				setStatuses(statuses);
				setDefaultStatuses(JSON.parse(JSON.stringify(statuses)));
				const tags = await getTags(props.type);
				// @ts-expect-error Itags
				setTags(tags);
				setDefaultTags(JSON.parse(JSON.stringify(tags)));
				const all_additional_columns = await getAdditionalColumns();
				const additional_columns = all_additional_columns.filter(ac => ac.type != 'ReportColumn');
				setAdditionalColumnsAtom(JSON.parse(JSON.stringify(all_additional_columns)));
				setAdditionalColumns(additional_columns);
				setDefaultAdditionalColumns(JSON.parse(JSON.stringify(additional_columns)));
				const additionalFieldColumns = (await getAdditionalFieldColumns()).map(t => ({ ...t, id: t.field_id }));
				setAdditionalFieldColumnsAtom(JSON.parse(JSON.stringify(additionalFieldColumns)));
				setAdditionalFieldColumns(additionalFieldColumns);
				setDefaultAdditionalFieldColumns(JSON.parse(JSON.stringify(additionalFieldColumns)));
				setSubSettings(['statuses', 'tags', 'additional_columns', 'calculated_fields', 'report_fields', 'additional_field_columns']);
				const defaultCalculatedFields = await getCalculatedFields();
				setCalculatedFieldsAtom(JSON.parse(JSON.stringify(defaultCalculatedFields)));
				setCalculatedFields(defaultCalculatedFields);
				setDefaultCalculatedFields(JSON.parse(JSON.stringify(defaultCalculatedFields)));
				break;
			}
			case TagType.ORDER: {
				const statuses = await getOrderStatuses();
				setStatuses(statuses);
				setDefaultStatuses(JSON.parse(JSON.stringify(statuses)));
				setSubSettings(['statuses']);
				break;
			}
			case TagType.OPPORTUNITY: {
				const statuses = (await getOpportunityStatuses()).map(s => ({
					name: s.name,
					locked: s.is_primary,
					id: s.id,
					color_code: s.status_color_code,
					probability: s.probability,
					sequence: s.sequence
				}));
				setStatuses(statuses);
				setDefaultStatuses(JSON.parse(JSON.stringify(statuses)));
				const tags = await getTags(props.type);
				// @ts-expect-error Itags
				setTags(tags);
				setDefaultTags(JSON.parse(JSON.stringify(tags)));
				setSubSettings(['statuses','tags']);
				break;
			}
			default: {
				const tags = await getTags(props.type);
				// @ts-expect-error Itags
				setTags(tags);
				setDefaultTags(JSON.parse(JSON.stringify(tags)));
				setSubSettings(['tags']);
			}
		}
		setLoadingState(LoadingStateEnum.LOADED);
	}

	React.useEffect(() => {
		fetchDatas();
	}, [props.type]);

	React.useEffect(() => {
		if (newCalculatedField) {
			if (isEditMode)	{
				const index = calculatedFields.findIndex(cf => cf.id === newCalculatedField.id);
				if (index !== -1) {
					calculatedFields[index] = newCalculatedField;
					setCalculatedFields([...calculatedFields]);
				}
			}
			else {
				calculatedFields.push(newCalculatedField);
				setCalculatedFields([...calculatedFields]);
			}
		}
	}, [newCalculatedField]);

	const onDragEnd = ({ source, destination }) => {
		if (!destination || !source || source.index === destination.index) return;
		const [removedItem] = statuses.splice(source.index, 1);
		statuses.splice(destination.index, 0, removedItem);
		setStatuses([...statuses].map((s, i) => ({ ...s, sequence: i + 1 })));
	};

	const createAdditionalColumn = React.useCallback(() => {
		const newAdditionalColumn: AdditionalColumn = {
			name: undefined,
			type: 'String',
			id: { unsync: Date.now() },
			data: undefined
		};
		setAdditionalColumns((additionalColumns) => [...additionalColumns, newAdditionalColumn]);
	}, []);

	const remainingFields = fields.filter(f => !additionalFieldColumns.some(afc => !afc.deleted && afc.field_id == f.id));
	const createAdditionalFieldColumn = () => {
		const field = remainingFields[0];
		if (!field) return;
		const newAdditionalFieldColumn: AdditionalFieldColumn = {
			field_id: field.id,
			field_name: field.name,
			id: { unsync: Date.now() }
		};
		setAdditionalFieldColumns((additionalFieldColumns) => [...additionalFieldColumns, newAdditionalFieldColumn]);
	};

	const createTag = React.useCallback(() => {
		const newTag = {
			color: colors[0].colorCode,
			name: undefined,
		};
		setTags((tags) => [...tags, { ...newTag, id: { unsync: Date.now() } }]);
	}, [colors]);

	const createStatus = React.useCallback(() => {
		switch (props.type) {
			case TagType.COMPANY:
			case TagType.ORDER: {
				setStatuses((statuses) => [...statuses, {
					color_code: colors[0].colorCode,
					sequence: statuses.length + 1,
					id: { unsync: Date.now() }
				}]);
				return;
			}
			case TagType.OPPORTUNITY: {
				setStatuses((statuses) => [...statuses, {
					color_code: colors[0].colorCode,
					probability: 50,
					sequence: statuses.length + 1,
					id: { unsync: Date.now() }
				}]);
				return;
			}
			default: return;
		}
	}, [props.type, colors]);

	const changeStatusProbability = (probability: number, id: Id) => () => {
		const status = statuses.find(s => s.id === id);
		if (!status || status.probability === undefined || status.locked) return;
		status.probability += probability;
		if (status.probability > 100 || status.probability < 0) {
			status.probability -= probability;
			return;
		}
		setStatuses([...statuses]);
	};

	if (loadingState === LoadingStateEnum.LOADING) return <ComponentLoader loadingState={loadingState} noBackground />;
	return <>
		<Prompt
			when={hasDifferences}
			message={translateToString('unsaved_changes_leave')}
		/>
		<SystemSettingsWrapper>
			{subSettings.includes('statuses') && <>
				<SystemSettingTitle>{translateToNode(tagTypeToString(props.type) + '_statuses')}</SystemSettingTitle>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="companies">
						{(provided) => (
							<FlexDiv flow='column' align='stretch' gap='1rem' height={`calc(45px * ${statuses.filter(s => !s.deleted).length} + 1rem * ${statuses.filter(s => !s.deleted).length - 1})`} {...provided.droppableProps} innerRef={provided.innerRef}>
								{statuses.sort((sa, sb) => (sa.sequence ?? 0) - (sb.sequence ?? 0)).map((s, index) => {
									if (s.deleted) return <></>;
									const isUnsync = typeof s.id !== 'number';
									const isPermitedToChange = usePermission({ objectAction: isUnsync ? 'CreateCompanySettings' : 'UpdateCompanySettings' });
									return <>
										<Draggable key={JSON.stringify(s.id)} draggableId={JSON.stringify(s.id)} index={index} isDragDisabled={s.sequence === undefined || !isPermitedToChange}>
											{(provided) => (
												<StatusWrapper innerRef={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													{s.sequence !== undefined && isPermitedToChange && <DandDDots />}
													<StatusContainer unsync={isUnsync}>
														{s.sequence !== undefined && <div style={{ width: '2em' }}>
															{s.sequence}
														</div>}
														<DropdownColor
															autoOptionUp
															disabled={!isPermitedToChange}
															dropdownStyle={{ height: '35px', width: '35px', optionWidth: `${56 * 4}px`, optionHeight: `${56 * 3}px`, optionUp: true }}
															// datalist={colorData}
															name={''}
															selectedValue={s.color_code}
															JSXButton={({ value }) => <>{value?.label}</>}
															onChange={(value: DropdownData<Color>) => {
																if (value.value.colorCode === s.color_code)
																	return;
																statuses[index].color_code = value.value.colorCode;
																setStatuses([...statuses]);
															} } />
														<TextStatusWrapper>
															{s.locked ? s.name :
																<InputStatusName
																	readOnly={!isPermitedToChange}
																	nameLength={s.name?.length ?? 0}
																	placeholder={isUnsync ? translateToString('new_status') : undefined}
																	unsync={isUnsync}
																	width='100%'
																	height='fit-content'
																	type='text'
																	onChange={e => {
																		const name = e.target.value;
																		statuses[index].name = name;
																		setStatuses([...statuses]);
																	} }
																	value={s.name}
																	onKeyDownCapture={blurOnEnterPressed} />}
														</TextStatusWrapper>
														{s.probability !== undefined && <StatusProbabilityWrapper locked={s.locked || !isPermitedToChange}>
															{s.probability} %
															{!s.locked && isPermitedToChange && <FlexDiv justify='center' flow='column'>
																<Arrow onClick={changeStatusProbability(10, s.id)} />
																<Arrow rotate180 onClick={changeStatusProbability(-10, s.id)} />
															</FlexDiv>}
														</StatusProbabilityWrapper>}
													</StatusContainer>
													{s.locked
														? <Lock src={lock} />
														: <Restricted to={{ objectAction: isUnsync ? 'CreateCompanySettings' : 'DeleteCompanySettings' }}>
															<DeleteDot onClick={_ => {
																const sequence = statuses[index].sequence;
																statuses[index].deleted = true;
																if (sequence !== undefined) {
																	statuses.forEach(s => {
																		if (s.sequence && s.sequence > sequence)
																			s.sequence -= 1;
																	});
																}
																if (isUnsync) {
																	statuses.splice(index, 1);
																}
																setStatuses([...statuses]);
															} } />
														</Restricted>
													}
													<RestrictedSuperAdmin>
														{typeof s.id == 'number' ? <TextButton onClick={() => copyToClipboard(s.id as number)}>{s.id}</TextButton> : <p>unsync</p>}
													</RestrictedSuperAdmin>
												</StatusWrapper>
											)}
										</Draggable>
									</>;
								})}
							</FlexDiv>
						)}
					</Droppable>
				</DragDropContext>
				<Restricted to={{ objectAction: 'CreateCompanySettings' }}>
					<Add onClick={createStatus} />
				</Restricted>
				<br />
				<div />
			</>}
			{subSettings.includes('tags') && <>
				<SystemSettingTitle>{translateToNode(tagTypeToString(props.type) + '_tags')}</SystemSettingTitle>
				<FlexDiv gap='5px' width='100%' flexWrap='wrap'>
					{tags
						.sort((a, b) => {
							if (typeof a.id === 'number' && typeof b.id !== 'number') return -1;
							if (typeof a.id !== 'number' && typeof b.id === 'number') return 1;
							if (typeof a.id !== 'number' && typeof b.id !== 'number') return 0;
							if (a.color && b.color && a.color !== b.color) {
								return a.color.localeCompare(b.color);
							}
							const defaultA = defaultTags.find(dt => dt.id === a.id);
							const defaultB = defaultTags.find(dt => dt.id === b.id);
							if (defaultA && defaultB) return defaultA.name!.localeCompare(defaultB.name!);

							let idA: number;
							if (typeof a.id === 'number') idA = a.id;
							else idA = a.id.unsync;

							let idB: number;
							if (typeof b.id === 'number') idB = b.id;
							else idB = b.id.unsync;
							return idA - idB;
						})
						.map((t, index) => {
							if (t.deleted) return <></>;
							const isUnsync = typeof t.id !== 'number';
							let internalId: number;
							if (typeof t.id === 'number') {
								internalId = t.id;
							} else {
								internalId = t.id.unsync;
							}
							return <TagContainer unsync={isUnsync} borderColor={t.color} key={`Tag[${internalId}]`}>
								<DropdownColor
									disabled={!usePermission({ objectAction: isUnsync ? 'CreateCompanySettings' : 'UpdateCompanySettings' })}
									autoOptionUp
									dropdownStyle={{ height: '20px', width: '20px', optionUp: true }}
									dotSize='20px'
									name={''}
									gallery
									selectedValue={t.color}
									// JSXButton={({ value }) => value?.value ? <Dot color={value.value.colorCode} size='20px' cursor='pointer' /> : <></>}
									onChange={(value: DropdownData<Color>) => {
										if (value.value.colorCode === t.color)
											return;
										tags[index].color = value.value.colorCode;
										setTags([...tags]);
									} } />
								<TextTagWrapper>
									<InputTagName
										readOnly={!usePermission({ objectAction: isUnsync ? 'CreateCompanySettings' : 'UpdateCompanySettings' })}
										nameLength={t.name?.length ?? 0}
										placeholder={isUnsync ? translateToString('new_tag') : undefined}
										unsync={isUnsync}
										width='100%'
										height='fit-content'
										type='text'
										onChange={e => {
											const name = e.target.value;
											tags[index].name = name;
											setTags([...tags]);
										} }
										value={t.name}
										onKeyDownCapture={blurOnEnterPressed} />
								</TextTagWrapper>
								<Restricted to={{ objectAction: isUnsync ? 'CreateCompanySettings' : 'DeleteCompanySettings' }}>
									<DeleteDot size='15px' fontSize='13px' onClick={_ => {
										tags[index].deleted = true;
										if (isUnsync) {
											tags.splice(index, 1);
										}
										setTags([...tags]);
									} } />
								</Restricted>
								<RestrictedSuperAdmin>
									{typeof t.id == 'number' ? <TextButton onClick={() => copyToClipboard(t.id as number)}>{t.id}</TextButton> : <p>unsync</p>}
								</RestrictedSuperAdmin>
							</TagContainer>
							;
						})}
					<Restricted to={{ objectAction: 'CreateCompanySettings' }}>
						<Add onClick={createTag}/>
					</Restricted>
					<br />
				</FlexDiv>
				<br />
			</>}
			{subSettings.includes('additional_columns') && <Restricted to={{ objectAction: 'ReadForm' }} >
				<SystemSettingTitle>{translateToNode('additional_columns')}</SystemSettingTitle>
				{additionalColumns.map((ac, index) => {
					if (ac.deleted) return <></>;
					const isUnsync = typeof ac.id !== 'number';
					const isPermitedToChange = usePermission({ objectAction: isUnsync ? 'CreateCompanySettings' : 'UpdateCompanySettings' });
					return <StatusWrapper key={JSON.stringify(ac.id)}>
						<StatusContainer unsync={isUnsync} width='450px'>
							<TextStatusWrapper>
								<InputStatusName
									readOnly={!isPermitedToChange}
									nameLength={ac.name?.length ?? 0}
									placeholder={isUnsync ? translateToString('new_column') : undefined}
									unsync={isUnsync}
									width='100%'
									height='fit-content'
									type='text'
									onChange={e => {
										const name = e.target.value;
										additionalColumns[index].name = name;
										setAdditionalColumns([...additionalColumns]);
									} }
									value={ac.name}
									onKeyDownCapture={blurOnEnterPressed}
								/>
							</TextStatusWrapper>
							{isUnsync ?
								<Dropdown
									readOnly
									autoOptionUp
									dropdownStyle={{
										containerBorder: 'none',
										width: '205px',
										optionWidth: '205px',
									}}
									name='DD-additional-column-type'
									selectedValue={{ label: translateToString('additional_column_types.' + ac.type), value: ac.type }}
									datalist={ADDITIONAL_COLUMN_TYPE_LST.filter(e => e != 'ReportColumn').map(e => ({ label: translateToString('additional_column_types.' + e), value: e }))}
									onChange={value => {
										additionalColumns[index].type = value.value;
										setAdditionalColumns([...additionalColumns]);

									}}
								/> : <div style={{ width: 195, flexShrink: 0 }}>{translateToString('additional_column_types.' + ac.type)}</div>}
						</StatusContainer>
						<Restricted to={{ objectAction: isUnsync ? 'CreateCompanySettings' : 'DeleteCompanySettings' }}>
							<DeleteDot onClick={_ => {
								additionalColumns[index].deleted = true;
								if (isUnsync) {
									additionalColumns.splice(index, 1);
								}
								setAdditionalColumns([...additionalColumns]);
							} }
							/>
						</Restricted>
						<Restricted to={{ objectAction: isUnsync ? 'CreateCompanySettings' : 'UpdateCompanySettings' }}>
							{
								ac.ishidded ?
									<GreyImg src={VisibilityOffIcon} title={translateToString('hide-on-company-summary')} onClick={() => {
										additionalColumns[index].ishidded = !additionalColumns[index].ishidded;
										setAdditionalColumns([...additionalColumns]);
									}}/>
									:
									<BlueImg src={VisibilityOnIcon} title={translateToString('hide-on-company-summary')} onClick={() => {
										additionalColumns[index].ishidded = !additionalColumns[index].ishidded;
										setAdditionalColumns([...additionalColumns]);
									}}/>
							}
						</Restricted>
						<Restricted to={{ objectAction: isUnsync ? 'CreateCompanySettings' : 'UpdateCompanySettings' }}>
							{(ac.type == 'Select' || ac.type == 'MultiSelect') && <SettingIconDiv src={SettingIcon} onClick={() => setAdditionalColumnSettingState({ isOpen: true, data: ac })}/>}
						</Restricted>
						<RestrictedSuperAdmin>
							{typeof ac.id == 'number' ? <TextButton onClick={() => copyToClipboard(ac.id as number)}>{ac.id}</TextButton> : <p>unsync</p>}
						</RestrictedSuperAdmin>
					</StatusWrapper>;
				})}
				<Restricted to={{ objectAction: 'CreateCompanySettings' }}>
					<Add onClick={createAdditionalColumn}/>
				</Restricted>
				<br />
			</Restricted>}
			{
				subSettings.includes('calculated_fields') && <Restricted to={{ objectAction: 'ReadForm' }} >
					<SystemSettingTitle>{translateToNode('calculated_fields')}</SystemSettingTitle>
					{calculatedFields.map((cf, index) => {
						const isUnsync = typeof cf.id !== 'number';
						const isPermitedToChange = usePermission({ objectAction: isUnsync ? 'CreateCompanySettings' : 'UpdateCompanySettings' });
						if (cf.deleted) return <></>;
						return <StatusWrapper key={JSON.stringify(cf.id)}>
							<StatusContainer unsync={isUnsync} width='450px'>
								<TextStatusWrapper>
									<InputStatusName
										readOnly={!isPermitedToChange}
										type='text'
										unsync={isUnsync}
										value={cf.field_name}
										nameLength={cf.field_name?.length ?? 0}
										disabled={true}
										onChange={e => {calculatedFields[index].field_name = e.target.value; setCalculatedFields([...calculatedFields]);}}
									/>
								</TextStatusWrapper>
							</StatusContainer>
							<Restricted to={{ objectAction: 'DeleteCompanySettings' }}>
								{isOwner(me) && <DeleteDot onClick={_ => {
									calculatedFields[index].deleted = true;
									setCalculatedFields([...calculatedFields]);
								}}
								/>}
							</Restricted>
							<RestrictedSuperAdmin>
								{typeof cf.id == 'number' ? <TextButton onClick={() => copyToClipboard(cf.id as number)}>{cf.id}</TextButton> : <p>unsync</p>}
							</RestrictedSuperAdmin>
						</StatusWrapper>;
					})}
					<RestrictedSuperAdmin><Restricted to={{ objectAction: 'CreateCompanySettings' }}>
						<Add onClick={() => {
							setIsEditMode(false);
							setNewCalculatedField(null);
							setIsCalculatedFieldsOpen(true);
						}}/>
					</Restricted>
					</RestrictedSuperAdmin>
					<CalculatedFieldsCreation
						isOpen={isCalculatedFieldsOpen}
						setIsOpen={setIsCalculatedFieldsOpen}
						setCalculatedField={setNewCalculatedField}
						calculatedField={null}
					>
					</CalculatedFieldsCreation>
				</Restricted>}
			{subSettings.includes('additional_field_columns') && <Restricted to={{ objectAction: 'ReadForm' }} >
				<SystemSettingTitle>{translateToNode('form_fields')}</SystemSettingTitle>
				{additionalFieldColumns.map((ac, index) => {
					if (ac.deleted) return <></>;
					const isUnsync = typeof ac.id !== 'number';
					return <StatusWrapper key={JSON.stringify(ac.id)}>
						<StatusContainer unsync={isUnsync} width='450px'>
							{isUnsync ?
								<Dropdown
									readOnly
									autoOptionUp
									dropdownStyle={{
										containerBorder: 'none',
										width: 'calc(450px - 4rem)',
										optionWidth: 'calc(450px - 4rem)',

									}}
									name='DD-additional-column-type'
									selectedValue={{ label: ac.field_name, value: ac.field_id }}
									datalist={remainingFields.map(e => ({ label: e.name, value: e.id }))}
									onChange={value => {
										additionalFieldColumns[index].field_id = value.value;
										if (typeof value.label === 'string') additionalFieldColumns[index].field_name = value.label;
										setAdditionalFieldColumns([...additionalFieldColumns]);

									}}
								/> : ac.field_name}
						</StatusContainer>
						<Restricted to={{ objectAction: isUnsync ? 'CreateCompanySettings' : 'DeleteCompanySettings' }}>
							<DeleteDot onClick={_ => {
								additionalFieldColumns[index].deleted = true;
								if (isUnsync) {
									additionalFieldColumns.splice(index, 1);
								}
								setAdditionalFieldColumns([...additionalFieldColumns]);
							} }
							/>
						</Restricted>
						<RestrictedSuperAdmin>
							{typeof ac.id == 'number' ? <TextButton onClick={() => copyToClipboard(ac.id as number)}>{ac.id}</TextButton> : <p>unsync</p>}
						</RestrictedSuperAdmin>
					</StatusWrapper>;
				})}
				<Restricted to={{ objectAction: 'CreateCompanySettings' }}>
					{remainingFields.length > 0 && <Add onClick={createAdditionalFieldColumn}/>}
				</Restricted>
				<br />

			</Restricted>}
		</SystemSettingsWrapper>
		<AdditionalColumnSettingPopup
			isOpen={additionalColumnSettingState.isOpen}
			onClickOut={() => setAdditionalColumnSettingState({ isOpen: false })}
			additionalColumn={additionalColumnSettingState.data}
			onSave={newAdditionalColumn => {
				const index = additionalColumns.findIndex(ac => ac.id === newAdditionalColumn.id);
				if (index === -1) return;
				additionalColumns[index] = newAdditionalColumn;
				setAdditionalColumns([...additionalColumns]);
			}}
		/>
	</>;
}

function AdditionalColumnSettingPopup(props: { isOpen: boolean, onClickOut: () => void, additionalColumn?: AdditionalColumn, onSave: (newAdditionalColumn: AdditionalColumn) => void}) {
	const { additionalColumn } = props;
	const [metadatas, setMetadatas] = React.useState<string[]>(additionalColumn?.data ?? ['']);
	React.useEffect(() => {
		setMetadatas(additionalColumn?.data ?? ['']);
	}, [additionalColumn]);

	return <Popup isOpen={props.isOpen} onClickOut={props.onClickOut} popupMode={PopupMode.Details}>
		{additionalColumn &&
		<PopupCreation
			title={additionalColumn.name ?? translateToString('new_column')}
			messageButton={translateToString('save')}
			onSubmit={() => {
				props.onSave({ ...additionalColumn, data: metadatas });
				props.onClickOut();
			}}
			canValidate={metadatas.length !== 0 && metadatas.every(md => md.trim().length !== 0)}
		>
			<CreationPopupContainer gap='1em'>
				{(additionalColumn.type === 'Select' || additionalColumn.type === 'MultiSelect') && <>
					{metadatas?.map((e, i) => <SelectMetaData
						title={translateToString('values')}
						value={e}
						key={`selectedMetaData[${i}]`}
						first={i === 0}
						onDelete={() => {
							setMetadatas(md => {
								md.splice(i, 1);
								return [ ...md ];
							});
						}}
						onChange={value => {
							setMetadatas(md => {
								md[i] = value;
								return [ ...md ];
							});
						}}
					/>) ?? <></>}
					<Add onClick={() => setMetadatas(md => {
						md.push('');
						return [ ...md ];
					})}/>
				</>}

			</CreationPopupContainer>

		</PopupCreation>}
	</Popup>;
}
