import { selector } from 'recoil';
import axios from 'axios';
import { URL_FOUNDATION } from '../config/keys';
import { FormTemplate } from '../containers_v2/forms/jsonValidator';
import { FieldType } from 'bindings/forms/FieldType';

// -----------------------[ FIELDS ]----------------------- //
export type Field = {
	id: number,
	name: string,
	data?: object | Array<string>,
	type: FieldType,
	slug: string,
	forms?: string[]
}

export type AddressField = {
	streetNumber?: string,
	route?: string,
	locality?: string,
	administrativeAreaLevel2?: string,
	administrativeAreaLevel1?: string,
	country?: string,
	postalCode?: string,
	latitude?: number,
	longitude?: number,
}

export type LinkedForm = {
	id: number,
	field_name: string
	slug: string
	form_name: string
}

export function isAddressField(field: unknown): field is AddressField {
	return typeof field === 'object' && !!field && 'country' in field;
}

export const AFormFields = selector<Field[]>({
	key: 'AFormFields',
	get: async() => getFields()
});

export function getFields(): Promise<Field[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/forms/fields`).then(res => res.data);
}

export function getFieldsLinkedForms(): Promise<LinkedForm[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/forms/fields/linked-forms`).then(res => res.data);
}

export function importFieldsBySlug(fields: Omit<Field, 'id'>[]): Promise<void> {
	return axios.post(`${URL_FOUNDATION}/api/v2/forms/fields/import`, fields).then(res => res.data);
} 

// -----------------------[ FORMS ]----------------------- //
export const AFormTemplate = selector<FormTemplate[]>({
	key: 'AFormTemplate',
	get: async() => getFormTemplates()
});

export async function getFormTemplates(body?: { limit?: number, offset?: number }): Promise<FormTemplate[]> {
	return await axios.post(
		`${URL_FOUNDATION}/api/v2/forms/templates/query`, body ?? {}
	).then(res => res.data);
}

export async function putFormTemplate(id: number, body: Partial<FormTemplate>): Promise<FormTemplate> {
	return await axios.put(`${URL_FOUNDATION}/api/v2/forms/templates/${id}`, body).then(res => res.data);
}